<template>
  <div>
    <div class="top">

    </div>
    <div class="logo">
      <div class="logo-left">
        <img src="../../assets/image/logo.png" alt="">
      </div>
    </div>

    <div class="content">
      <div class="content-top">
        <div class="top-title">确认订单信息</div>
        <div class="top-content">
          <div class="pro_img">
            <img :src="storedURL + productDetail.product_img1" alt="">
          </div>
          <div class="pro_name">
            <div class="name-title">商品编号</div>
            <div class="name-text">{{ productDetail.name }}</div>
          </div>
          <div class="pro_name">
            <div class="name-title">商品描述</div>
            <div class="name-text">{{ productDetail.description }}</div>
          </div>
          <div class="pro_name">
            <div class="name-title">商品单价</div>
            <div class="name-text">{{ univalent }}</div>
          </div>
          <div class="pro_name">
            <div class="name-title">商品数量</div>
            <div class="name-text">{{ num }}</div>
          </div>
        </div>
      </div>
      <div class="content-remark">
        <div class="remark-left">
          <div class="left-title">订单备注：</div>
          <div class="left-input">
            <el-input type="textarea" placeholder="请填写有关支付，收货，发票等方面的备注信息" v-model="textarea" maxlength="100"
              show-word-limit autosize>
            </el-input>
          </div>
        </div>
        <div class="remark-right">
          <div>总计： ￥{{ price }}</div>
        </div>
      </div>
      <div class="content-address">
        <div class="address-title">收货地址</div>
        <div class="address-content">
          <div class="address-item"><el-input v-model="provinceinput" placeholder="请输入省份"></el-input>省</div>
          <div class="address-item"><el-input v-model="cityinput" placeholder="请输入城市"></el-input>市</div>
          <div class="address-item"><el-input v-model="areainput" placeholder="请输入区/镇"></el-input>区</div>
          <div class="address-item"><el-input v-model="addressinput" placeholder="请输入详细地址"></el-input></div>
        </div>
      </div>
      <div class="content-contacts">
        <div class="contacts-title">下单联系人</div>
        <div class="contacts-content">
          <div class="contacts-item">联系人：<el-input v-model="contactsinput" placeholder="请输入内容"></el-input></div>
          <div class="contacts-item">电话：<el-input v-model="phoneinput" placeholder="请输入内容"></el-input></div>
          <div class="contacts-item">QQ:<el-input v-model="qqinput" placeholder="请输入内容"></el-input></div>
        </div>
      </div>
      <div class="content-delivery">
        <div class="delivery-title">配送方式</div>
        <div class="delivery-content">
          <div class="delivery-item">您可以选择：
            <el-select v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="content-payment">
        <div class="payment-title">支付方式</div>
        <div class="payment-content">
          <div class="payment-item">您可以选择：
            <el-select v-model="payvalue" placeholder="请选择" @change="paymentchange">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="content-buttom">
        <el-button type="primary" @click="submit">提交订单</el-button>
      </div>

      <el-dialog :visible.sync="buy_code" width="30%" @close="buy_close" center>
        <div class="dialog">
          <div class="title">微信支付</div>
          <div class="qrcode" id="qrcode" ref="qrcode"></div>
          <div class="price">订单金额：￥{{ price }}</div>
        </div>
      </el-dialog>

      <div ref="alipayWap" v-html="zfbform"></div>
    </div>

    <div id="right">
      <!--qq-->
      <div id="qq">
        <el-link href="https://wpa.qq.com/msgrd?v=3&uin=994437506&site=qqq&menu=yes" target="_blank">
          <div class="qq-Picture">
            <img src="../../assets/image/QQ.png" alt="">
          </div>
          <div>价格咨询</div>
        </el-link>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer2.vue';
import { productDetail } from '../../network/product';
import { cartadd, wxpayStatus } from '../../network/order';
import QRCode from "qrcodejs2"; // 引入qrcode

export default {
  components: {
    Footer
  },
  data() {
    return {
      userData: [],
      productId: 0,
      num: 0,
      univalent: 0,
      price: 0,
      productDetail: [],
      storedURL: '',
      textarea: '这里是备注',
      options: [{
        value: '1',
        label: '快递'
      }],
      options1: [{
        value: '1',
        label: '微信支付'
      }, {
        value: '2',
        label: '支付宝支付'
      }],
      value: '快递',
      payvalue: '支付宝支付',
      payment: 2,
      provinceinput: '广东',
      cityinput: '珠海',
      areainput: '香洲',
      addressinput: '瀚高大厦810',
      contactsinput: '聪明哥',
      phoneinput: '12345678910',
      qqinput: '123123121123',
      buy_code: false,
      zfbform: '',
      timer: null,  //为定时器准备的
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.storedURL = localStorage.getItem("URL");
    this.productId = this.$route.params.id;
    this.num = this.$route.params.num;
    this.univalent = this.$route.params.univalent;
    this.price = parseFloat(this.$route.params.price).toFixed(2);
  },
  mounted() {
    this.getproductDetail()
  },
  methods: {
    getproductDetail() {
      const id = this.productId
      productDetail(id).then(res => {
        const results = res.data.data;
        this.productDetail = results
      })
    },
    submit() {
      // window.open('https://wpa.qq.com/msgrd?v=3&uin=994437506&site=qqq&menu=yes');
      if (this.provinceinput == '') {
        this.$message({
          message: '请输入送货省份',
          type: 'warning'
        });
      } else if (this.cityinput == '') {
        this.$message({
          message: '请输入送货城市',
          type: 'warning'
        });
      } else if (this.areainput == '') {
        this.$message({
          message: '请输入送货区域/镇',
          type: 'warning'
        });
      } else if (this.addressinput == '') {
        this.$message({
          message: '请输入送货详细地址',
          type: 'warning'
        });
      } else if (this.contactsinput == '') {
        this.$message({
          message: '请输入联系人姓名',
          type: 'warning'
        });
      } else if (this.phoneinput == '') {
        this.$message({
          message: '请输入联系人电话号码',
          type: 'warning'
        });
      } else {
        let data = {
          user_id: this.userData.id,
          goods_id: this.productId,
          price: this.univalent,
          num: this.num,
          pay_type: this.payment,
          goods_price: this.price,
          remark: this.textarea,
          accept_province: this.provinceinput,
          accept_city: this.cityinput,
          accept_exp_area: this.areainput,
          accept_address: this.addressinput,
          accept_name: this.contactsinput,
          accept_mobile: this.phoneinput,
          qq: this.qqinput,
          name: this.productDetail.name
        }
        cartadd(data).then(res => {
          const results = res.data.data;
          if (data.pay_type == 2) { //支付宝支付

            this.zfbform = results.url
            // 防抖避免重复支付
            this.$nextTick(() => {
              // 提交支付表单
              this.$refs.alipayWap.children[0].submit();
              setTimeout(() => {
                // this.toPayFlag = false;
              }, 500);
            });
          } else { //微信支付
            if (res.data.msg == "订单创建成功") {
              this.buy_code = true;

              //循环请求查看是否支付成功
              // this.timer = setInterval(() => {
              //   this.wx_time(results.order);
              // }, 1000);

              this.$nextTick(() => {
                this.qrcode = new QRCode("qrcode", {
                  width: 150,//二维码宽度
                  height: 150,//二维码高度
                  text: "weixin://wxpay/bizpayurl?pr=uDKsQ4E00",//调用微信支付接口返回的微信特殊链接
                  colorDark: "#000",//颜色配置
                  colorLight: "#fff",
                });
              });
            }
          }
        })
      }
    },
    paymentchange(e) {
      this.payment = e
    },
    buy_close() {
      this.$refs.qrcode.innerHTML = "";//清空渲染的二维码
      this.buy_code = false;//关闭弹窗
    },
    // wx_time(orderid) {
    //   let orderid = orderid
    //   wxpayStatus(orderid).then(res => {
    //     // if(res= '支付成功') {
    //     //   this.$refs.qrcode.innerHTML = "";//清空渲染的二维码
    //     //   this.buy_code = false;//关闭弹窗
    //     //   this.timer = null
    //     // }else {

    //     // }
    //   })
    //   //
    // }
  },
}
</script>

<style scoped lang='scss'>
@media screen and (min-width: 401px) {
  #right {
    float: right;
    position: fixed;
    right: 20px;
    bottom: 200px;
    z-index: 99999999;

    #qq {
      /* line-height: 80px; */
      width: 70px;
      height: 70px;
      background-color: white;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.3);
      text-align: center;
      a {
        text-decoration: none;
        list-style: none;
        color: #000;
      }

      .qq-Picture img {
        margin-top: 5px;
        width: 40px;
        height: 40px;
      }
    }
  }
  .top {
    height: 40px;
    width: 100%;
    background-color: #69141e;
  }

  .logo {
    width: 100%;
    height: 20vh;

    .logo-left {
      width: 30%;
      height: 15vh;
      display: flex;
      justify-content: right;

      img {
        width: 70%;
        height: 100%;
      }
    }
  }

  .content {
    width: 80%;
    margin: 0px auto;

    .content-top {
      width: 100%;
      height: 25vh;
      border: 1px solid #eee;
      display: flex;
      flex-direction: column;

      .top-title {
        width: 100%;
        height: 20%;
        background-color: #f0f0f0;
        font-weight: bold;
        padding-left: 10px;
        display: flex;
        align-items: center;
      }

      .top-content {
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: space-around;

        .pro_img {
          width: 12%;
          height: 100%;
          padding: 10px;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .pro_name {
          width: 20%;
          height: 100%;
          text-align: center;

          .name-title {
            width: 100%;
            height: 20%;
            font-weight: bold;
          }

          .name-text {
            height: 80%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 12px;
          }
        }
      }
    }

    .content-remark {
      width: 100%;
      height: auto;
      display: flex;
      border: 1px solid #eee;

      .remark-left {
        width: 50%;
        display: flex;

        .left-title {
          width: 25%;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: right;
        }

        .left-input {
          width: 75%;
        }
      }

      .remark-right {
        width: 50%;
        text-align: right;
        display: flex;
        align-items: center;
        justify-content: right;
      }
    }

    .content-address,
    .content-contacts {
      margin-top: 10px;
      width: 100%;
      height: 20vh;
      border: 1px solid #eee;
      display: flex;
      flex-direction: column;

      .address-title,
      .contacts-title {
        width: 100%;
        height: 20%;
        background-color: #f0f0f0;
        font-weight: bold;
        padding-left: 10px;
        display: flex;
        align-items: center;
      }

      .address-content,
      .contacts-content {
        width: 100%;
        height: 80%;
        display: flex;
        align-items: center;
        justify-content: space-around;

        .address-item {
          display: flex;
          align-items: center;
        }

        .address-item:nth-child(1),
        .address-item:nth-child(2),
        .address-item:nth-child(3) {
          width: 15%;
        }

        .address-item:last-child {
          width: 50%;
        }

        .contacts-item {
          width: 30%;
          display: flex;
          align-items: center;
        }
      }
    }

    .content-delivery,
    .content-payment {
      margin-top: 10px;
      width: 100%;
      height: 10vh;
      border: 1px solid #eee;
      display: flex;
      flex-direction: column;

      .delivery-title,
      .payment-title {
        width: 100%;
        height: 40%;
        background-color: #f0f0f0;
        font-weight: bold;
        padding-left: 10px;
        display: flex;
        align-items: center;
      }

      .delivery-content,
      .payment-content {
        height: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .content-buttom {
      margin-top: 10px;
      width: 100%;
      display: flex;
      justify-content: right;
    }

    .dialog {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;

      .title {
        font-size: 16px;
        font-weight: bold;
        color: black;
      }

      .qrcode {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 10px;
      }

      .price {
        color: red;
        margin-top: 10px;
      }
    }
  }
}
</style>