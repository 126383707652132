<template>
  <div>
    <div class="footer-top">
        <div class="top">
            <div class="top-itemlist">
                <div class="item">
                    <div class="item-title">联系我们</div>
                    <div class="item-content">
                        <div class="content-img"><img src="../assets/image/phone.png" alt=""></div>
                        <div class="content-text">
                            <div>4008201186 (询价热线)</div>
                            <div>4008218857 (货期查询及售后服务)</div>
                        </div>
                    </div>
                    <div class="item-content">
                        <div class="content-img"><img src="../assets/image/email.png" alt=""></div>
                        <div class="content-text">
                            <div style="height: 40px;line-height: 40px;">服务邮箱: R.RSCN@rs.rsgroup.com</div>
                        </div>
                    </div>
                </div>
                <div class="item" style="margin-top: 10px;">
                    <div class="item-title">我们接受</div>
                    <div class="item-content">
                        <div class="content-img"><img src="../assets/image/zfbzflogo.png" alt=""></div>
                        <div class="content-img"><img src="../assets/image/wxzflogo.png" alt=""></div>
                    </div>
                </div>
            </div>
            <div class="top-itemlist">
                <div class="item">
                    <div class="item-title">服务</div>
                    <div class="item-contentlist">
                        <div class="contentlist-item">服务解决方案</div>
                        <div class="contentlist-item">包裹跟踪</div>
                        <div class="contentlist-item">在线报价</div>
                        <div class="contentlist-item">欧时放账账户</div>
                        <div class="contentlist-item">远期订单</div>
                        <div class="contentlist-item">送货方式</div>
                    </div>
                </div>
            </div>
            <div class="top-itemlist">
                <div class="item">
                    <div class="item-title">法律</div>
                    <div class="item-contentlist">
                        <div class="contentlist-item">数据保护</div>
                        <div class="contentlist-item">隐私条款</div>
                        <div class="contentlist-item">网站条款</div>
                        <div class="contentlist-item">邮件安全</div>
                    </div>
                </div>
            </div>
            <div class="top-itemlist">
                <div class="item">
                    <div class="item-title">关于祖荣</div>
                    <div class="item-contentlist">
                        <div class="contentlist-item">祖荣销售条款</div>
                        <div class="contentlist-item">账户和付款</div>
                        <div class="contentlist-item">企业集团</div>
                        <div class="contentlist-item"><router-link to="/aboutUs">关于我们</router-link></div>
                        <div class="contentlist-item">新闻中心</div>
                        <div class="contentlist-item">加入我们</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="but">
            <div class="but-img" @click="openUrl('https://beian.miit.gov.cn/')"><img src="../assets/image/dzyyzz.jpg" alt=""></div>
            <div class="but-text" @click="openUrl('https://beian.miit.gov.cn/')"><img src="../assets/image/gongan.png" alt="">粤ICP备 2022003163号</div>
        </div>
    </div>
    <div class="footer-but">
        <span>© ZR Components Ltd 中国广东省珠海市吉大园林路1号47栋1104房-1; 邮编: 519000</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  data() {
    return {

    }
  },
  created() {

  },
  onload() {

  },
  methods: {
    openUrl(url) {
        window.open(url,"_blank")
    }
  },
}
</script>

<style scoped lang='scss'>
a{
  text-decoration: none;
  color: #fff;
}
.router-link-active {
  text-decoration: none;
  color: white;
}
.footer-top {
    width: 100%;
    height: 450px;
    background-color: #32000f;
    .top {
        width: 100%;
        height: 370px;
        display: flex;
        padding: 30px;
        justify-content: space-between;
        .top-itemlist {
            width: 25%;
            .item {
                color: #fff;
                .item-title {
                    font-size: 18px;
                    text-align: left;
                    font-weight: bold;
                }
                .item-content {
                    display: flex;
                    .content-img {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        padding: 5px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .content-text {
                        text-align: left;
                        font-size: 16px;
                        padding: 1px;
                    }
                }
                .item-contentlist {
                    text-align: left;
                    margin-left: 20px;
                    margin-top: 15px;
                    .contentlist-item {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
    .but {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        .but-img {
            padding: 8px;
            width: 80px;
            height: 80px;
            img {
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }
        .but-text {
            font-size: 16px;
            color: #fff;
            height: 80px;
            line-height: 80px;
            margin-left: 40px;
            img {
                width: 40px;
                height: 40px;
                object-fit: contain;
            }
        }
    }
}
.footer-but {
    width: 100%;
    height: 40px;
    line-height: 40px;
    background-color: #69141e;
    text-align: center;
    span {
        color: white;
        font-size: 10px;
    }
}
@media screen and (max-width: 400px) {
  .footer-top {
    width: 100%;
    height: auto;
    .top {
        width: 100%;
        height: auto;
        padding: 10px;
        display: flex;
        flex-direction: column;
        .top-itemlist {
            width: 100%;
            .item {
                color: #fff;
                .item-title {
                    font-size: 17px;
                    text-align: center;
                    font-weight: bold;
                }
                .item-content {
                    display: flex;
                    justify-content: center;
                    .content-img {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        padding: 5px;
                        img {
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                        }
                    }
                    .content-text {
                        text-align: left;
                        font-size: 15px;
                        padding: 1px;
                    }
                }
                .item-contentlist {
                    text-align: center;
                    margin-left: 20px;
                    margin-top: 15px;
                    .contentlist-item {
                        margin-top: 5px;
                    }
                }
            }
        }
    }
  }
}
</style>