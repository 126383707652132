<template>
    <div>
        <div class="content">
            <div class="from">
                <div class="logo">
                    <div class="logo-left">注册</div>
                    <div class="logo-right">
                        <img src="../../assets/image/logo.png" alt="">
                    </div>
                </div>
                <div class="from-content">
                    <div class="from-item">
                        <div class="item-left" style="width: 20%;"><label for="userPhone">手机号:</label></div>
                        <div class="item-right"><input type="number" class="form-control" id="userPhone"
                                v-model="userPhone"></div>
                    </div>
                    <div class="from-item" style="margin-top: 10px;">
                        <div class="item-left" style="width: 20%;"><label for="userPassword1">密码:</label></div>
                        <div class="item-right"><input type="password" class="form-control" id="userPassword1" minlength="8"
                                maxlength="15" v-model="userPassword1"></div>
                    </div>
                    <div class="from-item" style="margin-top: 10px;">
                        <div class="item-left" style="width: 20%;"><label for="userPassword2">确认密码:</label></div>
                        <div class="item-right"><input type="password" class="form-control" id="userPassword2" minlength="8"
                                maxlength="15" v-model="userPassword2"></div>
                    </div>
                    <div class="from-item" style="margin-top: 10px;">
                        <div style="width: 50%;display: flex;height: 40px;line-height: 40px;">
                            <div style="width: 40%;"><label for="yzm">验证码：</label></div>
                            <div style="width: 60%;margin-left: 5px;"><input type="text" class="form-control" id="yzm"
                                    v-model="code">
                            </div>
                        </div>
                        <div>
                            <button :disabled="disabled" class="btn btn-primary" @click="getCode()">{{ title }}</button>
                        </div>
                    </div>
                </div>
                <div class="d-grid" style="margin-top: 0px;">
                    <button type="button" class="btn btn-primary" @click="register">立即注册</button>
                </div>
                <div class="line"></div>
                <div class="from-bottom">
                    <div>
                        <router-link to="/login">
                            <div>已有账号，去登录</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>

        <div class="bottom">
            <div>© 珠海祖荣科技有限公司 RS Components (Zhuhai) Co., Ltd. 中国广东省珠海市吉大园林路1号47栋1104房-1; 邮编: 519000</div>
        </div>
    </div>
</template>

<script>
import { Sendcode, register } from '../../network/user'
export default {
    data() {
        return {
            second: '', //倒计时
            disabled: false, //是否禁用按钮
            timer: null, //计时器
            userPhone: '',
            userPassword1: '',
            userPassword2: '',
            code: '',
        }
    },
    computed: {
        title() {
            return this.disabled ? `重新获取 ( ${this.second} ) s` : '获取验证码';
        },
    },
    created() {

    },
    onload() {

    },
    methods: {
        //获取短信验证码
        getCode() {
            let that = this
            let s = 60  //倒计时间
            if (that.userPhone.length === 11) {
                let data = {
                    phone: that.userPhone,
                };
                if (!that.timer) {
                    Sendcode(data).then(res => {
                        const { results } = res
                        console.log(results);
                    })
                    that.second = s
                    that.disabled = true
                    that.timer = setInterval(() => {
                        if (that.second > 0 && this.second <= s) {
                            that.second--
                        } else {
                            that.disabled = false
                            clearInterval(that.timer)
                            this.timer = null
                        }
                    }, 1000)
                }
            } else {
                alert('请输入正确手机号')
            }

        },
        register() {
            const errors = [];

            if (this.code === '') {
                errors.push('验证码不能为空');
            }

            if (this.userPassword1.length < 8) {
                errors.push('请设置最少8位数密码');
            }

            if (this.userPassword1 !== this.userPassword2) {
                errors.push('两次输入的密码不一致');
            }

            if (errors.length === 0) {
                const data = {
                    phone: this.userPhone,
                    password: this.userPassword1,
                    code: this.code
                };

                register(data)
                    .then(res => {
                        const results = res.data.msg;
                        alert(results)
                        if(res.data.msg == '注册成功') {
                            this.$router.push('/login')
                        }
                    })
                    .catch(error => {
                        // 处理注册失败的逻辑
                    });
            } else {
                alert(errors.join('\n'));
            }
        }
    },
}
</script>

<style scoped lang='scss'>
.content {
    background-color: #e6e6e6;
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;

    .from {
        width: 30%;
        height: 500px;
        background-color: #fff;

        .logo {
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: space-between;

            .logo-left {
                width: 30%;
                font-size: 33px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .logo-right {
                width: 35%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .from-content {
            padding: 20px;

            .from-item {
                width: 100%;
                height: 40px;
                display: flex;
                justify-content: space-between;

                .item-left {
                    height: 40px;
                    line-height: 40px;
                    text-align: center;
                    display: flex;
                }

                .item-right {
                    width: 80%;
                    height: 40px;
                }
            }
        }

        .d-grid {
            padding: 20px;
        }

        .line {
            width: 90%;
            height: 1px;
            margin: 20px auto;
            border-bottom: 1px solid #e0e0e0;
        }

        .from-bottom {
            width: 90%;
            margin: 30px auto;
            display: flex;
            justify-content: space-between;
            color: #0d6efd;

        }
    }
}

.bottom {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

a,
.router-link-active {
    text-decoration: none;
}
</style>