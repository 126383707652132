//引入封装的公共的请求插件
import request from '../network/request'

//创建订单
export function cartadd(data) {
    return request({
        url: '/order/order/cartadd',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

//支付宝支付成功改变订单状态
export function getOrderPayType(data) {
    return request({
        url: '/order/order/zfbpayStatus',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

//查询微信支付状态
export function wxpayStatus(orderid) {
    return request({
        url: '/order/order/wxpayStatus',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: {
            orderid: orderid,
        }
    })
}