<template>
  <div>
    <ProductHeader />

    <div class="content">
      <div class="topDetail">
        <div class="left">
          <div class="product">
            <div class="product-name">{{ productDetail.name }}详细参数资料</div>
            <div class="product-detail">
              <div class="detail-left">
                <el-image style="width: 200px; height: 200px" :src="url">
                </el-image>
              </div>
              <div class="detail-middle">
                <div class="middle-item" style="height: 48px;line-height: 48px;">制造商编号</div>
                <div class="middle-item" style="height: 48px;line-height: 48px;">制 造 商</div>
                <div class="middle-item" style="height: 48px;line-height: 48px;">品牌</div>
                <div class="middle-item" style="height: 48px;line-height: 48px;">供货</div>
                <div class="middle-item" style="height: 48px;line-height: 48px;">无铅情况/RoHs</div>
                <div class="middle-item" style="height: 60px;line-height: 60px;">描述</div>
              </div>
              <div class="detail-right">
                <div class="right-item" style="height: 48px;line-height: 48px;">{{ productDetail.e_name }}</div>
                <div class="right-item" style="height: 48px;line-height: 48px;">{{ productDetail.maker }}</div>
                <div class="right-item" style="height: 48px;line-height: 48px;">{{ productDetail.brank_name }}</div>
                <div class="right-item" style="height: 48px;line-height: 48px;">库存{{ productDetail.inventory }}</div>
                <div class="right-item" style="height: 48px;line-height: 48px;">无铅/符合RoHs</div>
                <div class="right-item" style="height: 60px;">{{ productDetail.description }}</div>
              </div>
            </div>
          </div>

          <div class="guide">
            <div class="guide-name">数据手册</div>
            <div class="guide-content">
              <div class="content-left">PDF资料</div>
              <div class="content-right" @click="pdfclick(productDetail.brank_pdf)">{{ productDetail.brank_pdf }}</div>
            </div>
          </div>

          <div class="parameter">
            <div class="parameter-name">参数信息</div>
            <div class="parameter-content">
              <div class="content-item"
                style="background-color: #e9e9e9;height: 35px;line-height: 35px;font-weight: bold;">
                <div class="item-left">参数</div>
                <div class="item-right">参数值</div>
              </div>
              <div class="content-item">
                <div class="item-left">商品目录</div>
                <div class="item-right">{{ productDetail.type_name }}</div>
              </div>
              <div class="content-item">
                <div class="item-left">电压</div>
                <div class="item-right">{{ productDetail.parms_5 }}</div>
              </div>
              <div class="content-item">
                <div class="item-left">功率</div>
                <div class="item-right">{{ productDetail.parms_3 }}</div>
              </div>
              <div class="content-item">
                <div class="item-left">封装</div>
                <div class="item-right">{{ productDetail.parms_1 }}</div>
              </div>
              <div class="content-item">
                <div class="item-left">阻值</div>
                <div class="item-right">{{ productDetail.parms_2 }}</div>
              </div>
              <div class="content-item">
                <div class="item-left">偏差</div>
                <div class="item-right">{{ productDetail.parms_4 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="inventory">
            <div class="inventory-name">库存： {{ productDetail.inventory }}</div>
            <div class="inventory-content">
              <div class="content-top">
                <div class="top-left">
                  <div class="left-item">价格梯度</div>
                  <div class="left-item">1+</div>
                  <div class="left-item">100+</div>
                  <div class="left-item">1000+</div>
                  <div class="left-item">2500+</div>
                  <div class="left-item">5000+</div>
                </div>
                <div class="top-right">
                  <div class="right-item">单价(含税)</div>
                  <div class="right-item">￥{{ productDetail.price }}</div>
                  <div class="right-item">￥{{ productDetail.price_2 }}</div>
                  <div class="right-item">￥{{ productDetail.price_3 }}</div>
                  <div class="right-item">￥{{ productDetail.price_4 }}</div>
                  <div class="right-item">￥{{ productDetail.price_5 }}</div>
                </div>
              </div>
              <div class="content-next">
                <el-checkbox v-model="radio">料盘7寸</el-checkbox>
                <el-popover style="margin-left: 15px;" placement="top-start" width="200" trigger="hover"
                  content="若购买整盘会自带料盘, 无需购买料盘">
                  <span slot="reference">￥2</span>
                </el-popover>
              </div>
              <div class="content-input">
                <div class="input-left">
                  <el-input-number v-model="num" controls-position="right" @change="handleChange"
                    :min="1"></el-input-number>
                </div>
                <div class="input-right">
                  <div>单价： ￥{{ univalent }}</div>
                  <div>总价： ￥{{ price }}</div>
                </div>
              </div>
              <div class="content-buttom">
                <div><el-button type="primary" @click="addcart()">加入购物车</el-button></div>
                <div><el-button @click="orderCheck">立即购买</el-button></div>
              </div>
              <div class="content-tips">
                购买数量不足MPQ时,均为剪切带或散料出货。
              </div>
              <div class="content-bot1" style="border-bottom: 1px solid #eee;">
                <div class="botitem">最小包：{{ productDetail.mpq }}</div>
                <div class="botitem">交期：3天-5天</div>
              </div>
              <div class="content-bot1">
                <div class="botitem">起订量：1</div>
                <div class="botitem">倍数：1</div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import ProductHeader from '@/components/product/ProductHeader.vue';
import Footer from '@/components/Footer2.vue';
import { productDetail } from '../../network/product';
import { addCart } from '../../network/user';

export default {
  components: {
    ProductHeader,
    Footer
  },
  data() {
    return {
      storedURL: '',
      productId: 0,
      productDetail: [],
      userData: [],
      url: '',
      radio: false,
      num: 1,//数量
      univalent: 0.2226,//单价
      price: 0,//总价
    }
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    this.storedURL = localStorage.getItem("URL");
  },
  mounted() {
    const id = this.$route.query.id
    this.productId = id
    this.getproductDetail()
  },
  methods: {
    getproductDetail() {
      const id = this.productId
      productDetail(id).then(res => {
        const results = res.data.data;
        this.productDetail = results
        this.url = this.storedURL + results.product_img1
        this.univalent = results.price
        this.price = results.price
      })
    },
    handleChange(value) {
      this.num = value; //数量
      const priceRanges = [
        { min: 1, max: 100, priceKey: 'price' },
        { min: 100, max: 1000, priceKey: 'price_2' },
        { min: 1000, max: 2500, priceKey: 'price_3' },
        { min: 2500, max: 5000, priceKey: 'price_4' },
        { min: 5000, max: Infinity, priceKey: 'price_5' }
      ];

      const range = priceRanges.find(range => this.num >= range.min && this.num < range.max);
      this.univalent = this.productDetail[range.priceKey];
      this.price = (this.univalent * this.num).toFixed(2); //总价=单价*数量
    },
    pdfclick(pdf) {
      window.open(this.storedURL + pdf)
    },
    orderCheck() {
      if (this.userData == '' || this.userData == null) {
        alert('请先登录')
        this.$router.push('/login')
      } else {
        this.$router.push({
          name: 'orderCheck', 
          params: {
            id: this.productId,
            num: this.num,
            univalent: this.univalent,
            price: this.price
          }
        })
        // this.$router.push({ path: '/orderCheck', query: { productId: this.productId } })
      }
    },
    addcart() {
      if (this.userData == '' || this.userData == null) {
        alert('请先登录')
        this.$router.push('/login')
      } else {
        let data = {
          userId: this.userData.id,
          productid: this.productId,
          count: this.num
        }
        addCart(data).then(res => {
          const msg = res.data.msg
          this.$message({
            message: msg,
            type: 'success'
          });
        })
      }
    }
  },
}
</script>

<style scoped lang='scss'>
.content {
  width: 100%;
  height: auto;
  background-color: #f9f9f9;
  padding: 10px;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;

  .topDetail {
    width: 90%;
    margin: 10px auto;
    display: flex;
    justify-content: space-between;

    .left {
      width: 70%;

      .product {
        width: 100%;
        border: 1px solid #d8d8d8;

        .product-name {
          width: 100%;
          height: 60px;
          line-height: 60px;
          background-color: #e9e9e9;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;
        }

        .product-detail {
          width: 100%;
          height: 300px;
          display: flex;

          .detail-left {
            width: 30%;
            border-right: 1px solid #d8d8d8;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .detail-middle {
            width: 20%;
            text-align: right;

            .middle-item {
              border-right: 1px solid #d8d8d8;
              border-bottom: 1px solid #d8d8d8;
              text-align: center;
              background-color: #f5f5f5;
            }
          }

          .detail-right {
            width: 50%;

            .right-item {
              border-bottom: 1px solid #d8d8d8;
              text-align: center;
            }
          }
        }
      }

      .guide {
        width: 100%;
        border: 1px solid #d8d8d8;
        margin-top: 15px;

        .guide-name {
          width: 100%;
          height: 50px;
          line-height: 50px;
          background-color: #e9e9e9;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;

        }

        .guide-content {
          display: flex;
          height: 50px;
          line-height: 50px;
          text-align: center;

          .content-left {
            background-color: #f5f5f5;
            width: 30%;
            height: 100%;
            border-right: 1px solid #d8d8d8;
          }

          .content-right {
            width: 70%;
            height: 100%;
          }
        }
      }

      .parameter {
        width: 100%;
        border: 1px solid #d8d8d8;
        margin-top: 15px;

        .parameter-name {
          width: 100%;
          height: 50px;
          line-height: 50px;
          background-color: #e9e9e9;
          text-align: center;
          border-bottom: 1px solid #d8d8d8;
        }

        .parameter-content {
          width: 100%;
          padding: 10px;

          .content-item {
            display: flex;
            text-align: center;
            border: 1px solid #d8d8d8;

            .item-left {
              width: 40%;
              border-right: 1px solid #d8d8d8;
            }

            .item-right {
              width: 60%;
            }
          }
        }
      }
    }

    .right {
      width: 28%;

      .inventory {
        width: 100%;
        border: 1px solid #d8d8d8;

        .inventory-name {
          width: 100%;
          height: 60px;
          line-height: 60px;
          background-color: #e9e9e9;
          text-align: center;
        }

        .inventory-content {
          width: 100%;
          background-color: white;

          .content-top {
            width: 100%;
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            border-bottom: 1px solid #d8d8d8;

            .top-left {
              width: 40%;
              text-align: right;
              margin-top: 10px;

              .left-item {
                margin-top: 5px;
              }
            }

            .top-right {
              width: 40%;
              text-align: left;
              margin-top: 10px;

              .right-item {
                margin-top: 5px;
              }
            }
          }

          .content-next {
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #d8d8d8;
          }

          .content-input {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .input-left {
              width: 60%;
              margin-left: 10px;
            }

            .input-right {
              width: 40%;
              font-size: 12px;
            }
          }

          .content-buttom {
            width: 100%;
            display: flex;
            justify-content: space-around;
          }

          .content-tips {
            width: 100%;
            font-size: 14px;
            text-align: center;
            color: #ad9d9d;
            border-bottom: 1px solid #eee;
          }

          .content-bot1 {
            width: 100%;
            display: flex;
            justify-content: space-around;
            height: 40px;
            line-height: 40px;

            .botitem {
              font-size: 15px;
            }
          }
        }
      }
    }
  }
}
</style>