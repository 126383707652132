<template>
  <div>
    <ProductHeader />
    <div class="contnet">
      <div class="nav">
        <nav style="--bs-breadcrumb-divider: '>';" aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item fs-6"><router-link to="/" style="color: black;">首页</router-link></li>
            <li class="breadcrumb-item active fs-6" aria-current="page">商品目录</li>
            <!-- 一大类 -->
            <li class="breadcrumb-item fs-6">
              <el-select v-model="typeValue" placeholder="请选择" size="mini" @change="typechange">
                <el-option v-for="item in typeList" :key="item.value" :label="item.label" :value="item.value"
                  :disabled="item.disabled">
                </el-option>
              </el-select>
            </li>
            <!-- 二大类 -->
            <li class="breadcrumb-item fs-6" v-if="subclassId != 0">
              <el-select v-model="subclassValue" placeholder="请选择" size="mini" @change="subclasschange">
                <el-option v-for="item in subclassList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </li>
            <!-- 三大类 -->
            <li class="breadcrumb-item fs-6" v-if="threeShow">
              <el-select v-model="threeValue" placeholder="请选择" size="mini" @change="threeListchange">
                <el-option v-for="item in threeList" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
            </li>
          </ol>
        </nav>
      </div>
      <div class="type">
        <!-- <div class="type-title" v-show="typeShow">{{ typeValue }}: {{ typeCount }}</div>
        <div class="type-title" v-show="subclassShow">{{ subclassValue }}: {{ subCount }}</div> -->
        <div class="type-title" v-show="typeShow">过滤结果 :{{ typeCount }}</div>
        <div class="type-title" v-show="subclassShow">过滤结果 :{{ subCount }}</div>
        <div class="type-title" v-show="threeShow">过滤结果 :{{ threeCount }}</div>

        <div class="type-content">
          <ul v-for="item, index in subclassList" :key="index" v-show="typeShow">
            <li type="disc" @click="subclasschange(item.value, item.label, item.count)">
              <a style="text-decoration: none;">{{ item.label }} ({{ item.count }})</a>
            </li>
          </ul>
          <ul v-for="item, index in threeList" :key="index" v-show="subclassShow">
            <li type="disc" @click="threechange(item.value, item.label, index)">
              <a style="text-decoration: none;">{{ item.label }} ({{ item.count }})</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="screen" v-loading="loading">
        <div style="height: 10px;"></div>
        <div class="screen-title">
          <!-- <div class="title-left">过滤结果 : </div>
          <div class="title-right" v-show="typeShow">{{ typeCount }}</div>
          <div class="title-right" v-show="subclassShow">{{ subCount }}</div>
          <div class="title-right" v-show="threeShow">{{ threeCount }}</div> -->
          <div class="title-left">筛选条件</div>
          <div class="tag" v-for="item in tags" :key="item.name">
            <el-tag type="success" closable @close="tagsClose(item)">{{ item.name }}</el-tag>
          </div>
        </div>
        <div class="screen-content">
          <div class="content-item" style="width: 20%;">
            <div class="item-title">品牌</div>
            <div class="item-content">
              <div class="content-search">
                <!-- <el-input placeholder="请输入品牌" v-model="input1" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input> -->
                <el-autocomplete class="inline-input" v-model="input1" :fetch-suggestions="querySearch"
                  placeholder="请输入内容" :trigger-on-focus="false" @select="handleSelect"></el-autocomplete>
              </div>
              <div class="content-list">
                <div class="list-item" :class="{ selected: selectedId === item.id }" v-for="item in brandlist"
                  :key="item.id" @click="addtags(item.name, item.id, 'brank')">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="content-item" style="width: 15%;" v-if="typeId == 144 || typeId == 143">
            <div class="item-title">封装(英寸)</div>
            <div class="item-content">
              <div class="content-search">
                <el-input placeholder="封装" v-model="input2" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </div>
              <div class="content-list">
                <div class="list-item" v-for="item in parms_1" :key="item.id"
                  @click="addtags(item.parms_1, 2, 'parms1_search')"> {{ item.parms_1 }}</div>
              </div>
            </div>
          </div>
          <div class="content-item" style="width: 15%;" v-if="typeId == 144">
            <div class="item-title">功率</div>
            <div class="item-content">
              <div class="content-search">
                <el-input placeholder="功率" v-model="input3" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </div>
              <div class="content-list">
                <div class="list-item" v-for="item in parms_3" :key="item.id"
                  @click="addtags(item.parms_3, 3, 'parms3_search')"> {{ item.parms_3 }}</div>
              </div>
            </div>
          </div>
          <div class="content-item" style="width: 15%;" v-if="typeId == 144 || typeId == 143">
            <div class="item-title">偏差</div>
            <div class="item-content">
              <div class="content-search">
                <el-input placeholder="偏差" v-model="input4" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </div>
              <div class="content-list">
                <div class="list-item" v-for="item in parms_4" :key="item.id"
                  @click="addtags(item.parms_4, 4, 'parms4_search')"> {{ item.parms_4 }}</div>
              </div>
            </div>
          </div>
          <div class="content-item" style="width: 15%;" v-if="typeId == 144">
            <div class="item-title">阻值(欧姆)</div>
            <div class="item-content">
              <div class="content-search">
                <el-input placeholder="阻值" v-model="input5" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </div>
              <div class="content-list">
                <div class="list-item" v-for="item in parms_2" :key="item.id"
                  @click="addtags(item.parms_2, 5, 'parms2_search')"> {{ item.parms_2 }}</div>
              </div>
            </div>
          </div>
          <div class="content-item" style="width: 15%;" v-if="typeId == 144 || typeId == 143">
            <div class="item-title">温度系数</div>
            <div class="item-content">
              <div class="content-search">
                <el-input placeholder="温度系数" v-model="input6" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </div>
              <div class="content-list">
                <div class="list-item" v-for="item in temp" :key="item.id" @click="addtags(item.temp, 6, 'temp_search')">
                  {{ item.temp }}
                </div>
              </div>
            </div>
          </div>
          <div class="content-item" style="width: 15%;" v-if="typeId == 144">
            <div class="item-title">包装(MPQ)</div>
            <div class="item-content">
              <div class="content-search">
                <el-input placeholder="包装" v-model="input7" class="input-with-select">
                  <el-button slot="append" icon="el-icon-search"></el-button>
                </el-input>
              </div>
              <div class="content-list">
                <div class="list-item" v-for="item in parms_6" :key="item.id"
                  @click="addtags(item.parms_6, 7, 'parms6_search')"> {{ item.parms_6 }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="screen-but">
          <el-checkbox v-model="inventorychecked"
            @change="handleChange(inventorychecked, 'inventory_name')">有库存</el-checkbox>
          <el-checkbox v-model="pricechecked" @change="handleChange(pricechecked, 'price_name')">有价格</el-checkbox>
        </div>
      </div>
      <div class="table">
        <div class="table-top">
          <div class="top-left">导出表格</div>
          <div class="top-right">
            <div class="right-search">
              <el-input placeholder="从结果中搜索" v-model="input4" class="input-with-select">
                <el-button slot="append" icon="el-icon-search"></el-button>
              </el-input>
            </div>
            <div class="right-total">共 358,787 条</div>
            <div class="right-but">
              <div><el-button type="primary"><i class="el-icon-arrow-left"></i></el-button></div>
              <div style="margin-left: 20px;"><el-button type="primary"><i class="el-icon-arrow-right"></i></el-button>
              </div>
            </div>
          </div>
        </div>
        <div class="table-content">
          <el-table class="table-fixed" :data="tableData" style="width: 100%" stripe border @row-click="handleRowClick">
            <el-table-column prop="product_img1" label="图片" width="120" align="center">
              <template slot-scope="scope">
                <img class="table-img" :src="storedURL + scope.row.product_img1" alt="">
              </template>
            </el-table-column>
            <el-table-column prop="name" label="商品" width="130" align="center">
            </el-table-column>
            <el-table-column prop="brank_name" label="品牌" width="120" align="center">
              <template slot-scope="scope">
                <div>{{ scope.row.brank_name }}</div>
                <div class="imgsize" @click.stop="pdfclick(scope.row.brank_pdf)">
                  <div class="img"><img src="../../assets/image/gongan.png" alt=""></div>
                  <div style="color: blueviolet;">数据手册</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="description" label="描述" width="150" align="center">
            </el-table-column>
            <el-table-column prop="price" label="单价(含税)" width="150" align="center">
              <template slot-scope="scope">
                <span>1+： {{ scope.row.price }}</span><br>
                <span>100+： {{ scope.row.price_2 }}</span><br>
                <span>1000+： {{ scope.row.price_3 }}</span><br>
                <span>2500+： {{ scope.row.price_4 }}</span><br>
                <span style="color: blueviolet;">5000+： {{ scope.row.price_5 }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="inventory" label="库存" width="120" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.inventory != 0">库存：{{ scope.row.inventory }}</div>
                <div v-if="scope.row.inventory == 0">期货: {{ scope.row.futures }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="parms_1" label="封装" width="120" align="center">
            </el-table-column>
            <el-table-column prop="parms_3" label="功率" width="120" align="center">
            </el-table-column>
            <el-table-column prop="parms_4" label="偏差" width="120" align="center">
            </el-table-column>
            <el-table-column prop="parms_2" label="阻值" width="120" align="center">
            </el-table-column>
            <el-table-column prop="temp" label="温度系数" width="120">
            </el-table-column>
            <el-table-column prop="parms_6" label="包装" width="120">
            </el-table-column>
            <!-- <el-table-column prop="zip" label="正向电压Vf" width="120">
            </el-table-column>
            <el-table-column prop="zip" label="正向电流If" width="120">
            </el-table-column>
            <el-table-column prop="zip" label="波长" width="120">
            </el-table-column>
            <el-table-column prop="zip" label="颜色" width="120">
            </el-table-column>
            <el-table-column prop="zip" label="齐纳电压Vz" width="120">
            </el-table-column> -->
          </el-table>
          <div class="pagination">
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="page"
              :page-sizes="[10, 20, 50]" :page-size="10" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
          </div>

        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script>
import ProductHeader from '@/components/product/ProductHeader.vue';
import Footer from '@/components/Footer2.vue';
import { Type, Subclass, ProductList } from '../../network/product'
export default {
  components: {
    ProductHeader,
    Footer
  },
  data() {
    return {
      typeList: [],
      subclassList: [],
      threeList: [],
      typeValue: '电阻', //一级类名称
      subclassValue: '贴片电阻', //二级类名称
      threeValue: '薄膜电阻', //三级类名称
      typeId: 144, //一级类id
      subclassId: 0, //二级类id
      threeId: 0, //三级类id
      typeCount: 1, //一级类总数
      subCount: 0, //二级类总数
      threeCount: 0, //三级类总数
      searchId: 0, //最终查询的类id
      typeShow: true,
      subclassShow: false,
      threeShow: false,
      storedURL: '',
      brandlist: [],
      parms_1: [],
      parms_2: [],
      parms_3: [],
      parms_4: [],
      parms_6: [],
      temp: [],
      tags: [],
      input1: '',
      input2: '',
      input3: '',
      input4: '',
      input5: '',
      input6: '',
      input7: '',
      loading: false,
      inventorychecked: false,
      pricechecked: false,
      tableData: [],
      page: 1, //分页页数
      size: 10, //分页条数，默认20
      total: 0, //总条数
      brankid: 0,
      temp_name: '',
      parms1_name: '',
      parms2_name: '',
      parms3_name: '',
      parms4_name: '',
      parms6_name: '',
      inventory_name: 1, //0代表选中，1代表没选中
      price_name: 1, //0代表选中，1代表没选中
      selectedId: null,
    }
  },
  created() {
    this.storedURL = localStorage.getItem("URL");
    this.loading = true
    // this.typeId = this.$route.params.typeid;
    // this.subclassId = this.$route.params.subclassid;
    // this.threeId = this.$route.params.threeid;

    let typedata = {
      typeId: this.typeId,
      subclassId: this.subclassId,//二级类id
      threeId: this.threeId,//三级类id
      page: this.page,
      number: this.size
    };
    Type(typedata).then(res => {
      const results = res.data.data
      this.typeList = results
      this.typeCount = results[this.typeId].count
      this.tableData = results.productList
    })
    Subclass(typedata).then(res => {
      const results = res.data.data
      this.subclassList = results.column
      this.brandlist = results.brand
      this.parms_1 = results.parms_1
      this.parms_2 = results.parms_2
      this.parms_3 = results.parms_3
      this.parms_4 = results.parms_4
      this.parms_6 = results.parms_6
      this.temp = results.temp
      this.total = results.total
      this.tableData = results.product
      this.page = results.page
      this.loading = false
    })
    // ProductList(this.typeId, this.subclassId, this.threeId, this.page, this.size).then(res => {
    //   const results = res.data.data
    //   this.tableData = results
    // })
  },
  onload() {

  },
  computed: {

  },
  methods: {
    typechange(e) {
      this.typeId = e
      this.subclassId = 0
      this.threeId = 0
      this.searchId = e
      this.typeShow = true
      this.subclassShow = false
      this.threeShow = false

      let typedata = {
        typeId: this.typeId,
        subclassId: this.subclassId,
        threeId: this.threeId,
        page: this.page,
        number: this.size
      };
      Type(typedata).then(res => {
        const results = res.data.data
        this.typeList = results
        this.typeCount = results[this.typeId].count
        this.tableData = results.productList
      })
      Subclass(typedata).then(res => {
        const results = res.data.data
        this.subclassList = results.column
        this.brandlist = results.brand
        this.parms_1 = results.parms_1
        this.parms_2 = results.parms_2
        this.parms_3 = results.parms_3
        this.parms_4 = results.parms_4
        this.parms_6 = results.parms_6
        this.temp = results.temp
        this.total = results.total
        this.tableData = results.product
        this.page = results.page
      })
      // ProductList(this.typeId, this.subclassId, this.threeId, this.page, this.size).then(res => {
      //   const results = res.data.data
      //   this.tableData = results
      // })
    },
    subclasschange(id, name, count, e) {
      this.subclassValue = id
      this.subclassId = id
      this.searchId = id
      this.threeId = 0
      this.subCount = count
      this.typeShow = false
      this.subclassShow = true
      this.threeShow = false
      this.resetVariables()
      let subclassdata = {
        typeId: this.typeId,
        subclassId: this.subclassId,
        threeId: this.threeId
      };
      Subclass(subclassdata).then(res => {
        const results = res.data.data
        this.threeList = results.column
        this.brandlist = results.brand
        this.parms_1 = results.parms_1
        this.parms_2 = results.parms_2
        this.parms_3 = results.parms_3
        this.parms_4 = results.parms_4
        this.parms_6 = results.parms_6
        this.temp = results.temp
        this.total = results.total
        this.tableData = results.product
        this.page = results.page
      })
      // ProductList(this.typeId, this.subclassId, this.threeId, this.page, this.size).then(res => {
      //   const results = res.data.data
      //   this.tableData = results
      // })
    },
    threechange(id, name, index) {
      this.threeValue = name
      this.threeId = id
      this.searchId = id
      this.typeShow = false
      this.subclassShow = false
      this.threeShow = true
      this.resetVariables()
      this.threeCount = this.threeList[id].count
      ProductList(this.typeId, this.subclassId, this.threeId, this.page, this.size).then(res => {
        const results = res.data.data
        this.tableData = results
      })
    },
    threeListchange(e) {
      this.threeId = e
      this.searchId = e
      this.typeShow = false
      this.subclassShow = false
      this.threeShow = true
      this.threeCount = this.threeList[e].count
      this.resetVariables()
      let subclassdata = {
        typeId: this.typeId,
        subclassId: this.subclassId,
        threeId: this.threeId
      };
      Subclass(subclassdata).then(res => {
        const results = res.data.data
        this.brandlist = results.brand
        this.parms_1 = results.parms_1
        this.parms_2 = results.parms_2
        this.parms_3 = results.parms_3
        this.parms_4 = results.parms_4
        this.parms_6 = results.parms_6
        this.temp = results.temp
      })
      ProductList(this.typeId, this.subclassId, this.threeId, this.page, this.size).then(res => {
        const results = res.data.data
        this.tableData = results

        // this.threeCount = this.threeList[index].count
      })
    },
    addtags(name, id, type) {
      const existingTag = this.tags.find(tag => tag.id === id);
      if (existingTag) {
        existingTag.name = name; // 修改name的值
      } else {
        this.tags.push({ name: name, id: id }); // 添加新标签
      }
      if (type == 'brank') {
        this.brankid = id
        this.selectedId = id
      } else if (type == 'parms1_search') {
        this.parms1_name = name
      }
      else if (type == 'parms2_search') {
        this.parms2_name = name
      }
      else if (type == 'parms3_search') {
        this.parms3_name = name
      }
      else if (type == 'parms4_search') {
        this.parms4_name = name
      }
      else if (type == 'parms6_search') {
        this.parms6_name = name
      } else if (type == 'temp_search') {
        this.temp_name = name
      }

      let typedata = {
        typeId: this.typeId,
        subclassId: this.subclassId,//二级类id
        threeId: this.threeId,//三级类id
        brankID: this.brankid,//品牌id
        parms1_name: this.parms1_name,
        parms2_name: this.parms2_name,
        parms3_name: this.parms3_name,
        parms4_name: this.parms4_name,
        parms6_name: this.parms6_name,
        temp_name: this.temp_name
      };
      Subclass(typedata).then(res => {
        const results = res.data.data
        this.subclassList = results.column
        this.brandlist = results.brand
        this.parms_1 = results.parms_1
        this.parms_2 = results.parms_2
        this.parms_3 = results.parms_3
        this.parms_4 = results.parms_4
        this.parms_6 = results.parms_6
        this.temp = results.temp
        this.total = results.total
        this.tableData = results.product
        this.page = results.page
      })
    },
    tagsClose(tag) {
      const item = this.tags.indexOf(tag)
      this.tags.splice(item, 1)
    },
    handleSizeChange(val) {
      this.size = val
      let typedata = {
        typeId: this.typeId,
        subclassId: this.subclassId,//二级类id
        threeId: this.threeId,//三级类id
        brankID: this.brankid,//品牌id
        parms1_name: this.parms1_name,
        parms2_name: this.parms2_name,
        parms3_name: this.parms3_name,
        parms4_name: this.parms4_name,
        parms6_name: this.parms6_name,
        temp_name: this.temp_name,
        page: this.page,
        number: this.size
      };
      Subclass(typedata).then(res => {
        const results = res.data.data
        this.subclassList = results.column
        this.brandlist = results.brand
        this.parms_1 = results.parms_1
        this.parms_2 = results.parms_2
        this.parms_3 = results.parms_3
        this.parms_4 = results.parms_4
        this.parms_6 = results.parms_6
        this.temp = results.temp
        this.total = results.total
        this.tableData = results.product
        this.page = results.page
      })
    },
    handleCurrentChange(val) {
      this.page = val
      let typedata = {
        typeId: this.typeId,
        subclassId: this.subclassId,//二级类id
        threeId: this.threeId,//三级类id
        brankID: this.brankid,//品牌id
        parms1_name: this.parms1_name,
        parms2_name: this.parms2_name,
        parms3_name: this.parms3_name,
        parms4_name: this.parms4_name,
        parms6_name: this.parms6_name,
        temp_name: this.temp_name,
        page: this.page,
        number: this.size
      };
      Subclass(typedata).then(res => {
        const results = res.data.data
        this.subclassList = results.column
        this.brandlist = results.brand
        this.parms_1 = results.parms_1
        this.parms_2 = results.parms_2
        this.parms_3 = results.parms_3
        this.parms_4 = results.parms_4
        this.parms_6 = results.parms_6
        this.temp = results.temp
        this.total = results.total
        this.tableData = results.product
        this.page = results.page
      })
    },
    querySearch(queryString, cb) {
      var restaurants = this.brandlist;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    handleSelect(item) {
      console.log(item);
    },
    //重置筛选
    resetVariables() {
      this.tags = []
      this.brankid = 0
      this.parms1_name = ''
      this.parms2_name = ''
      this.parms3_name = ''
      this.parms4_name = ''
      this.parms6_name = ''
      this.temp_name = ''
      this.inventory_name = 1
      this.price_name = 1
      this.inventorychecked = false
      this.pricechecked = false
    },
    //筛选勾选框点击事件
    handleChange(e, name) {
      let value = e ? 0 : 1;

      this[name] = value;

      let typedata = {
        typeId: this.typeId,
        subclassId: this.subclassId,
        threeId: this.threeId,
        brankID: this.brankid,
        parms1_name: this.parms1_name,
        parms2_name: this.parms2_name,
        parms3_name: this.parms3_name,
        parms4_name: this.parms4_name,
        parms6_name: this.parms6_name,
        temp_name: this.temp_name,
        inventory_name: this.inventory_name,
        price_name: this.price_name
      };

      Subclass(typedata).then(res => {
        const results = res.data.data;
        this.subclassList = results.column;
        this.brandlist = results.brand;
        this.parms_1 = results.parms_1;
        this.parms_2 = results.parms_2;
        this.parms_3 = results.parms_3;
        this.parms_4 = results.parms_4;
        this.parms_6 = results.parms_6;
        this.temp = results.temp;
      });
    },
    pdfclick(pdf) {
      console.log(pdf);
      window.open(this.storedURL + pdf)
    },
    handleRowClick(row, column, event) {
      const id = row.id
      this.$router.push({ path: '/productDetail', query: { id: id } })
    }
  },
}
</script>

<style scoped lang='scss'>
.selected {
  border: 1px solid blue;
}

.imgsize {
  display: flex;

  .img {
    width: 20px;
    height: 20px;

    img {
      width: 100%;
      height: 100%;
    }
  }

}

.table-img {
  width: 100px;
  background-size: cover;
}

.contnet {
  width: 100%;
  background-color: #f9f9f9;
  border-top: 1px solid #eee;

  .nav {
    width: 85%;
    margin: 10px auto;

    .breadcrumb {
      width: 100%;
    }
  }

  .type {
    width: 85%;
    margin: 0 auto;

    .type-title {
      width: 100%;
      height: 40px;
      line-height: 40px;
      background-color: #e9e9e9;
      font-weight: bold;
      padding-left: 20px;
    }

    .type-content {
      width: 100%;
      background-color: white;
      color: #32000f;
      display: flex;
      flex-wrap: wrap;

      ul {
        width: 33.33%;
        /* 让每行排列3个项目 */
        box-sizing: border-box;
      }
    }
  }

  .screen {
    width: 85%;
    height: 420px;
    margin: 0 auto;
    background-color: #e9e9e9;

    .screen-title {
      display: flex;
      padding-left: 20px;
      height: 40px;

      .title-left {
        font-size: 15px;
        font-weight: bold;
        line-height: 30px;
      }

      .title-right {
        font-size: 20px;
        font-weight: bold;
        color: #0b75be;
      }
    }

    .screen-content {
      width: 100%;
      height: 315px;
      display: flex;
      justify-content: space-between;

      .content-item {
        margin-left: 10px;
        background-color: #fff;

        .item-title {
          background-color: #e9e9e9;
          font-size: 14px;
        }

        .item-content {
          .content-search {
            width: 100%;
          }

          .content-list {
            height: 250px;
            width: 100%;
            overflow: auto;

            .list-item {
              width: 100%;
              height: 30px;
              padding: 5px;
            }
          }
        }
      }
    }

    .screen-but {
      height: 20px;
      margin-left: 20px;
      margin-top: 15px;
    }
  }

  .table {
    width: 85%;
    margin: 20px auto;

    .table-top {
      width: 100%;
      height: 60px;
      background-color: #32000f;
      display: flex;
      justify-content: space-between;

      .top-left {
        color: #fff;
        line-height: 60px;
        margin-left: 20px;
      }

      .top-right {
        height: 100%;
        display: flex;

        .right-search {
          background-color: #32000f;
        }

        .right-total {
          height: 60px;
          line-height: 50px;
          background-color: #32000f;
          color: white;
          font-size: 14px;
        }

        .right-but {
          display: flex;
          background-color: #32000f;
        }
      }
    }

    .table-content {
      width: 100%;
      height: auto;

      .pagination {
        width: 100%;
        display: flex;
        justify-content: center;
      }
    }
  }
}
</style>