import Vue from 'vue'
import VueRouter from 'vue-router'
// import HomeView from '../views/HomeView.vue'
import home from '@/views/home/home'
import aboutUs from '@/views/home/aboutUs'
import login from '@/views/home/login'
import register from '@/views/home/register'
import product from '@/views/product/product'
import productDetail from '@/views/product/productDetail'
import orderCheck from '@/views/order/ordercheck'
import userHome from '@/views/user/userHome'
import userCart from '@/views/user/userCart'
import userSetting from '@/views/user/userSetting'
import userAddress from '@/views/user/userAddress'
import userCoupon from '@/views/user/userCoupon'
import userOrder from '@/views/user/userOrder'




Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/aboutUs',
    name: 'aboutUs',
    component: aboutUs
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: '/product',
    name: 'product',
    component: product
  },
  {
    path: '/productDetail',
    name: 'productDetail',
    component: productDetail
  },
  {
    path: '/orderCheck',
    name: 'orderCheck',
    component: orderCheck,
    props: true
  },
  {
    path: '/userHome',
    name: 'userHome',
    component: userHome,
    children: [
      {
        path: '', // 空 为当前路由的默认路由
        name: 'userCart',
        component: userCart
      },
      {
        path: '/userHome/userCart',
        name: 'userCart',
        component: userCart,
        meta: { title: '购物车' }
      },
      {
        path: '/userHome/userOrder',
        name: 'userOrder',
        component: userOrder,
        meta: { title: '订单中心' }
      },
      {
        path: '/userHome/userSetting',
        name: 'userSetting',
        component: userSetting,
        meta: { title: '用户设置' }
      },
      {
        path: '/userHome/userAddress',
        name: 'userAddress',
        component: userAddress,
        meta: { title: '地址管理' }
      },
      {
        path: '/userHome/userCoupon',
        name: 'userCoupon',
        component: userCoupon,
        meta: { title: '我的优惠卷' }
      },
    ]
  },
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

// 组件路由 = 前置路由守卫 === 未登录直接跳转login
router.beforeEach((to, from, next) => {
  console.log(to, from)
  // 拿到本地的user用户数据
  const user = JSON.parse(localStorage.getItem("userData"));

  // 判断to（要进入的位置） 不为login时，判断是否已有user 无则转到login
  if (to.path === '/userHome') {
    if (user) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
