<template>
    <div>
        <div class="content">
            <div class="from">
                <div class="logo">
                    <div class="logo-left segment">登录</div>
                    <div class="logo-right">
                        <img src="../../assets/image/logo.png" alt="">
                    </div>
                </div>
                <div class="from-content">
                    <div class="mb-3">
                        <label for="formGroupExampleInput" class="form-label">账号</label>
                        <input type="text" class="form-control" id="exampleInputEmail1" placeholder="请输入手机号"
                            v-model="phone">
                    </div>
                    <div class="mb-3">
                        <label for="formGroupExampleInput2" class="form-label">密码</label>
                        <input type="password" class="form-control" id="inputPassword1" placeholder="请输入密码"
                            v-model="password">
                        <div class="col-auto">
                            <span id="passwordHelpInline" class="form-text">
                                长度必须为 8-15 个字符。
                            </span>
                        </div>
                    </div>
                    <div class="d-grid" style="margin-top: 40px;">
                        <button type="button" class="btn btn-primary" @click="userLogin">登录</button>
                    </div>
                </div>
                <div class="line"></div>
                <div class="from-bottom">
                    <div>
                        <router-link to="/register">
                            <div>立即注册</div>
                        </router-link>
                    </div>
                    <div>忘记密码</div>
                </div>
            </div>
        </div>

        <div class="bottom">
            <div>© 珠海祖荣科技有限公司 RS Components (Zhuhai) Co., Ltd. 中国广东省珠海市吉大园林路1号47栋1104房-1; 邮编: 519000</div>
        </div>
    </div>
</template>

<script>
import { login } from '../../network/user'
export default {
    data() {
        return {
            password: '',
            phone: '',
            userData: []
        }
    },
    created() {

    },
    onload() {

    },
    methods: {
        userLogin() {
            const data = {
                phone: this.phone,
                password: this.password,
            };
            login(data).then(res => {
                if (res.data.msg == '登入成功') {
                    this.userData = res.data.data
                    localStorage.setItem("userId", this.userData.id);
                    localStorage.setItem("userData", JSON.stringify(this.userData));
                    alert('登录成功')
                    this.$router.push('/')
                } else {
                    alert(res.data.msg)
                }
            }).catch(error => {
                // 处理注册失败的逻辑
            });
        }
    },
}
</script>

<style scoped lang='scss'>
$ruler: 16px;
$color-bg: #EBECF0;
$color-shadow: #BABECC;
$color-white: #FFF;

.segment {
    padding: $ruler*2 0;
    text-align: center;
}

.content {
    background-color: $color-bg;
    width: 100%;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;

    .from {
        width: 30%;
        height: 500px;
        background-color: #fff;

        .logo {
            width: 100%;
            height: 20%;
            display: flex;
            justify-content: space-between;

            .logo-left {
                width: 30%;
                font-size: 33px;
                height: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .logo-right {
                width: 35%;
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }

        .from-content {
            padding: 20px;

            input {
                margin-right: $ruler/2;
                box-shadow: inset 2px 2px 5px $color-shadow, inset -5px -5px 10px $color-white;
                width: 100%;
                box-sizing: border-box;
                transition: all 0.2s ease-in-out;
                appearance: none;
                -webkit-appearance: none;

                &:focus {
                    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
                }
            }

            button {
                font-weight: bold;
                box-shadow: -5px -5px 20px $color-white, 5px 5px 20px $color-shadow;
                transition: all 0.2s ease-in-out;
                cursor: pointer;
                font-weight: 600;

                &:hover {
                    box-shadow: -2px -2px 5px $color-white, 2px 2px 5px $color-shadow;
                }

                &:active {
                    box-shadow: inset 1px 1px 2px $color-shadow, inset -1px -1px 2px $color-white;
                }

                .icon {
                    margin-right: $ruler/2;
                }

                &.unit {
                    border-radius: $ruler/2;
                    line-height: 0;
                    width: $ruler*3;
                    height: $ruler*3;
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    margin: 0 $ruler/2;
                    font-size: $ruler*1.2;

                    .icon {
                        margin-right: 0;
                    }
                }

            }

        }

        .line {
            width: 90%;
            height: 1px;
            margin: 30px auto;
            border-bottom: 1px solid #e0e0e0;
        }

        .from-bottom {
            width: 90%;
            margin: 30px auto;
            display: flex;
            justify-content: space-between;
            color: #0d6efd;

        }
    }
}

.bottom {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}

a,
.router-link-active {
    text-decoration: none;
}
</style>