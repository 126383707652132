<template>
    <div>
        <!-- <div class="top">
            <div class="toplist">
                <div class="item">超值优惠</div>
                <div class="item">行业资源</div>
                <div class="item">客户支持</div>
            </div>
        </div> -->

        <!-- <div class="top-content">
            <div class="content-left">
                <img src="../../assets/image/logo.png" alt="">
            </div>
            <div class="content-right">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="请输入产品编号或关键字" aria-label="Recipient's username"
                        aria-describedby="basic-addon2">
                    <span class="input-group-text" id="basic-addon2">搜索</span>
                </div>
            </div>
        </div> -->

        <!-- <div class="top-nav">
            <ul class="nav">
                <li class="nav-item">
                    <router-link to="/" class="nav-link active">首页</router-link>
                </li>
                <li class="nav-item">
                    <router-link to="/product" class="nav-link">所有产品</router-link>
                </li>
                <li class="nav-item">
                    <a class="nav-link">所有品牌</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link">我的账户</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link">服务和解决方案</a>
                </li>
            </ul>
        </div> -->

        <div class="pagetitle">
            <h4>我的购物车</h4>
            <p>现货库存，下午5点半前下单成功将于当天发货。如遇国家限制类进口产品或海关商检正常检验，时间会有延长，下单后客服人员将会电话通知您。</p>
        </div>
        <el-card class="box-card">
            <div class="cart-content">
                <div class="content-empty" v-if="cartList.length === 0">
                    <el-empty description="您的购物车为空"></el-empty>
                </div>
                <div v-else>
                    <div class="cart-item" v-for="item in cartList" :key="item.id">
                        <div class="cart-item-img">
                            <img :src="storedURL + item.product_img1" alt="">
                        </div>
                        <div class="cart-item-info">
                            <div class="info-item">
                                <div class="item-title">产品名称</div>
                                <div class="item-text">{{ item.name }}</div>
                            </div>
                            <div class="info-item" v-if="item.inventory != 0">
                                <div class="item-title">库存</div>
                                <div class="item-text">
                                    {{ item.inventory }}
                                </div>
                            </div>
                            <div class="info-item" v-if="item.inventory == 0">
                                <div class="item-title">期货</div>
                                <div class="item-text">
                                    {{ item.futures }}
                                </div>
                            </div>
                            <div class="info-item">
                                <div class="item-title">产品价格</div>
                                <div class="item-text">
                                    <div v-if="item.count >= 1 && item.count < 100">1+：￥{{ item.price }}</div>
                                    <div v-if="item.count >= 100 && item.count < 1000">100+：￥{{ item.price_2 }}</div>
                                    <div v-if="item.count >= 1000 && item.count < 2500">1000+：￥{{ item.price_3 }}</div>
                                    <div v-if="item.count >= 2500 && item.count < 5000">2500+：￥{{ item.price_4 }}</div>
                                    <div v-if="item.count >= 5000">5000+：￥{{ item.price_5 }}</div>
                                </div>
                            </div>
                            <div class="info-item">
                                <div class="item-title">数量</div>
                                <div class="item-text">
                                    <el-input-number v-model="item.count" @change="handleChange" :min="1"></el-input-number>
                                </div>
                            </div>
                            <div class="info-item">
                                <div class="item-title">产品总价</div>
                                <div class="item-text">
                                    ￥{{ item.totalPrice }}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="cart-footer">
                        <div class="total-info">
                            <div class="total-text">购物车总价：</div>
                            <div class="total-price">
                                <div class="price-unit">¥</div>
                                <div class="price-text">{{ cartPrice }}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>

        <!-- <Footer /> -->
    </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import { queryCart } from '../../network/user';

export default {
    components: {
        Footer,
    },
    data() {
        return {
            userId: 0,
            userData: [],
            cartList: [],// 购物车列表
            storedURL: '',
        }
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.storedURL = localStorage.getItem("URL");
    },
    mounted() {
        const id = this.$route.params.id
        this.userId = this.userData.id
        this.getCartList()
    },
    watch: {
        cartList: {
            deep: true,
            handler() {
                for (let i = 0; i < this.cartList.length; i++) {
                    const item = this.cartList[i];
                    if (item.count < 100) {
                        item.priceByCount = item.price;
                    } else if (item.count < 1000) {
                        item.priceByCount = item.price_2;
                    } else if (item.count < 2500) {
                        item.priceByCount = item.price_3;
                    } else if (item.count < 5000) {
                        item.priceByCount = item.price_4;
                    } else {
                        item.priceByCount = item.price_5;
                    }
                    item.totalPrice = (item.priceByCount * item.count).toFixed(2);
                }
            },
        },
    },
    computed: {
        // 计算总价
        cartPrice() {
            let total = 0;
            for (let i = 0; i < this.cartList.length; i++) {
                const item = this.cartList[i];
                total += parseFloat(item.totalPrice);
            }
            return total;
        },
    },
    methods: {
        getCartList() {
            let data = {
                userId: this.userId
            }
            queryCart(data).then(res => {
                const results = res.data.data
                this.cartList = results
            })
        },
        handleChange(value) {
            console.log(value);
        }
    },
}
</script>

<style scoped lang='scss'>
@media screen and (min-width: 401px) {
    .top {
        width: 100%;
        background-color: #69141e;
        color: white;

        .toplist {
            display: flex;
            margin-left: 100px;

            .item {
                margin-left: 20px;
            }
        }
    }

    .top-content {
        width: 80%;
        margin: 0 auto;
        display: flex;

        .content-left {
            width: 30%;
            height: 15vh;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .content-right {
            width: 70%;
            height: 15vh;
            display: flex;
            align-items: center;
        }
    }

    .top-nav {
        width: 100%;
        height: 45px;
        background-color: #4b5861;

        .nav {
            display: flex;
            justify-content: space-between;

            .nav-item {
                a {
                    color: white;
                }
            }
        }
    }

    .pagetitle {
        // margin-left: 130px;
        // margin-top: 20px;

        p {
            font-size: 12px;
        }
    }

    .cart-content {
        width: 80%;
        margin: 0 auto;

        .cart-item {
            width: 100%;
            height: 150px;
            display: flex;
            border-bottom: 1px solid #eee;

            .cart-item-img {
                width: 15%;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 10px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .cart-item-info {
                width: 85%;
                height: 150px;
                display: flex;
                justify-content: space-around;

                .info-item {
                    height: 150px;
                    margin-top: 10px;

                    .item-title {
                        text-align: center;
                        font-weight: bold;
                        font-size: 17px;
                        height: 20px;
                        line-height: 20px;
                    }

                    .item-text {
                        height: 130px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .cart-footer {
            width: 100%;
            height: 50px;
            line-height: 50px;

            .total-info {
                display: flex;
                float: right;

                .total-price {
                    display: flex;
                }
            }
        }
    }
}

@media screen and (max-width: 400px) {
    .top {
        width: 100%;
        background-color: #69141e;
        color: white;

        .toplist {
            display: flex;
            justify-content: space-around;
        }
    }

    .top-content {
        width: 80%;
        margin: 0 auto;

        .content-left {
            width: 100%;
            height: 15vh;

            img {
                width: 100%;
                height: 100%;
            }
        }

        .content-right {
            width: 100%;
            height: 10vh;
            display: flex;
            align-items: center;
        }
    }

    .top-nav {
        width: 100%;
        background-color: #4b5861;

        a {
            color: white;
        }
    }

    .pagetitle {
        width: 100%;
        text-align: center;

        p {
            font-size: 12px;
        }
    }

    .cart-content {
        width: 100%;
        margin: 0 auto;

        .cart-item {
            width: 100%;
            height: 150px;
            display: flex;
            border-bottom: 1px solid #eee;

            .cart-item-img {
                width: 15%;
                height: 150px;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .cart-item-info {
                width: 85%;
                height: 150px;
                display: flex;
                justify-content: space-around;

                .info-item {
                    height: 150px;
                    margin-top: 10px;

                    .item-title {
                        text-align: center;
                        font-weight: bold;
                        font-size: 17px;
                        height: 20px;
                        line-height: 20px;
                    }

                    .item-text {
                        height: 130px;
                        text-align: center;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }

        .cart-footer {
            width: 100%;
            height: 50px;
            line-height: 50px;

            .total-info {
                display: flex;
                float: right;

                .total-price {
                    display: flex;
                }
            }
        }
    }
}</style>