//引入封装的公共的请求插件
import request from '../network/request'

export function Sendcode(data) {
    return request({
        url: '/user/user/sendcode',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

export function register(data) {
    return request({
        url: '/user/user/register',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

export function login(data) {
    return request({
        url: '/user/user/login',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

//加入购物车
export function addCart(data) {
    return request({
        url: '/user/user/addCart',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

//查询购物车
export function queryCart(data) {
    return request({
        url: '/user/user/queryCart',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

//查询用户信息
export function getUserInfo(data) {
    return request({
        url: '/user/user/getUserInfo',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

//查询用户信息
export function editUserInfo(data) {
    return request({
        url: '/user/user/editUserInfo',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}

//查询用户信息
export function userOrderList(data) {
    return request({
        url: '/user/user/userOrderList',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}