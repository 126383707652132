<template>
    <div>
        <div class="top">
            <div class="top-left">
                <div class="left-item" style="border-right: 1px solid #fff;">客户支持</div>
                <div class="left-item" style="border-right: 1px solid #fff;">行业资源</div>
                <div class="left-item">超值优惠</div>
            </div>
        </div>
        <div class="buttom">
            <div class="buttom-left">
                <!-- <div class="dropdown">
                    <el-cascader v-model="value" ref="refHandle" popper-class="removeRadio" :options="options"
                        :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name' }"
                        @change="handleChange">
                    </el-cascader>
                </div> -->
                <div class="middle-right">
                    <div class="middle-right">
                        <div class="right-item">
                            <router-link to="/login">
                                <div class="flex-container">
                                    <img src="../assets/image/user.png" alt="">
                                    <div>登录/注册</div>
                                </div>
                            </router-link>
                        </div>
                        <div class="right-item" @click="goCart()">
                            <img src="../assets/image/cart.png" alt="">
                            <div>购物车</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="buttom-line" style="margin-left: 10px;">
                <div class="line"></div>
            </div>
            <div class="guoqi">
                <img src="../assets/image/guoqi.jpeg" alt="">
            </div>
            <div class="buttom-right">
                <!-- <div class="input-group">
                    <input type="text" class="form-control" placeholder="请输入产品型号或参数" aria-label="Recipient's username"
                        aria-describedby="basic-addon2">
                    <span class="input-group-text" id="basic-addon2">搜索</span>
                </div> -->
                <el-input placeholder="请输入产品型号或参数" v-model="input" class="input-with-select">
                    <el-button style="background-color: red;" slot="append" icon="el-icon-search"></el-button>
                </el-input>
            </div>
            <div class="buttom-logo">
                <router-link to="/">
                    <div class="img"><img src="../assets/image/logo1.png" alt=""></div>
                </router-link>
            </div>
        </div>
        <div style="height: 30px;width: 100%;"></div>
    </div>
</template>

<script>
import { Type3 } from '@/network/home'
export default {
    name: 'Header',
    data() {
        return {
            value: [],
            options: [],
            typeid: 0,
            subclassid: 0,
            threeid: 0,
            userData: [],
            input: '',
            select: '零件',
        }
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        Type3().then(res => {
            const results = res.data.data
            this.options = results
        })
    },
    onload() {

    },
    mounted() {
        //点击文本就让它自动点击前面的input就可以触发选择。但是因组件阻止了冒泡，暂时想不到好方法来触发。
        //这种比较耗性能，暂时想不到其他的，能实现效果了。
        setInterval(function () {
            document.querySelectorAll(".el-cascader-node__label").forEach(el => {
                el.onclick = function () {
                    if (this.previousElementSibling) this.previousElementSibling.click();
                };
            });
        }, 1000);
    },
    methods: {
        handleChange(value) {
            this.typeid = value[0]
            if (value[1] == undefined) {
                this.subclassid = 0
            } else {
                this.subclassid = value[1]
            }
            if (value[2] == undefined) {
                this.threeid = 0
            } else {
                this.threeid = value[2]
            }
            console.log(this.typeid, this.subclassid, this.threeid);
            if (this.$refs.refHandle) {
                this.$refs.refHandle.dropDownVisible = false; //监听值发生变化就关闭它
            }
            this.$router.push('/product')
        },
        goCart() {
            if (this.userData == '' || this.userData == null) {
                alert('请先登录')
                this.$router.push('/login')
            } else {
                const id = this.userData.id
                // this.$router.push({ path: '/userHome/userCart', params: { id: id } })
                this.$router.push({
                    name: 'userCart',
                    params: {
                        id: id
                    }
                })
            }
        },
        gouserHome() {
            this.$router.push('/userHome')
        }
    },
}
</script>

<style scoped lang='scss'>
.removeRadio .el-radio__inner {
    border-radius: 0;
    border: 0;
    width: 170px;
    height: 34px;
    background-color: transparent;
    cursor: pointer;
    box-sizing: border-box;
    position: absolute;
    top: -18px;
    left: -19px;
}

.removeRadio .el-radio__input.is-checked .el-radio__inner {
    background: transparent;
}

a {
    text-decoration: none;
    color: black;
}

.router-link-active {
    text-decoration: none;
}

.flex-container {
    display: flex;
    align-items: center;
}

.top {
    width: 100%;
    height: 38px;
    background-color: #69141e;

    .top-left {
        width: 100%;
        height: 38px;
        display: flex;
        align-items: center;
        color: #fff;
        margin-left: 100px;

        .left-item {
            height: 30px;
            line-height: 30px;
            font-size: 16px;
            text-align: center;
            width: 100px;
        }
    }
}

.middle {
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;

    .middle-left {
        height: 80px;
        width: 150px;
        margin-left: 100px;

        .left-img {
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                width: 100%;

            }
        }
    }

    .middle-right {
        height: 80px;
        width: 390px;
        margin-right: 100px;
        display: flex;

        .right-item {
            width: 130px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-weight: bold;

            img {
                width: 40px;
                height: 40px;
                object-fit: contain;
            }


        }
    }
}

.buttom {
    width: 100%;
    height: 60px;
    background-color: #e9e9e9;
    display: flex;

    .buttom-left {
        margin-left: 100px;
        height: 60px;
        line-height: 60px;

        .middle-right {
            height: 60px;
            width: auto;
            display: flex;

            .right-item {
                width: 130px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;

                img {
                    width: 40px;
                    height: 40px;
                    object-fit: contain;
                }


            }
        }
    }

    .buttom-line {
        height: 50px;
        width: 1px;
        margin-top: 5px;

        .line {
            height: 50px;
            border: 1px solid #fff;
        }

    }

    .guoqi {
        width: 90px;
        height: 60px;
        margin-left: 10px;
        padding: 10px;

        img {
            width: 100%;
            height: 100%;
        }
    }

    .buttom-middle {
        height: 60px;
        width: 70px;
        margin-left: 10px;

        .middle-checkbox {
            margin-top: 10px;
            display: flex;
            justify-content: center;
        }

        .middle-text {
            font-size: 13px;
        }
    }

    .buttom-right {
        width: 45%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;

        .input-group {
            margin-left: 10px;
        }
    }

    .buttom-logo {
        height: 60px;
        width: 150px;
        margin-left: 20px;
        display: flex;
        line-height: 60px;

        .img {
            width: 100%;
            height: 100%;

            img {
                height: 100%;
                width: 100%;

            }
        }
    }
}

@media screen and (max-width: 400px) {
    .top {
        width: 100%;

        .top-left {
            width: 100%;
            display: flex;
            margin-left: 0;
            justify-content: space-around;

            .left-item {
                font-size: 14px;
                text-align: center;
            }
        }
    }

    .middle {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .middle-left {
            height: 80px;
            width: 150px;
            margin-left: 100px;

            .left-img {
                width: 100%;
                height: 100%;

                img {
                    height: 100%;
                    width: 100%;

                }
            }
        }

        .middle-right {
            height: 80px;
            width: 100%;

            display: flex;

            .right-item {
                width: 130px;
                height: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: bold;

                img {
                    width: 40px;
                    height: 40px;
                    object-fit: contain;
                }


            }
        }
    }

    .buttom {
        width: 100%;
        height: auto;
        background-color: #e9e9e9;
        display: flex;
        flex-direction: column;
        margin-top: 90px;

        .buttom-left {
            width: 100%;
            height: 60px;
            line-height: 60px;
        }

        .buttom-line {
            display: none;
        }

        .buttom-right {
            width: 100%;
            height: 60px;
            display: flex;
            justify-content: center;
            align-items: center;

            .input-group {
                margin-left: 0px;
            }
        }
    }
}</style>