//引入封装的公共的请求插件
import request from '../network/request'

//获取一级类
export function Type(data) {
    return request({
        url: '/product/product/type',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data,
        responseType: 'json', // 指定返回数据类型为Json
    })
}

//获取二级类
export function Subclass(data) {
    return request({
        url: '/product/product/subclass',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: data
    })
}


//获取产品列表
export function ProductList(typeId,subclassId,threeId,page,size) {
    return request({
        url: '/product/product/productList',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: {
            typeId: typeId,
            subclassId: subclassId,
            threeId: threeId,
            page: page,
            size: size
        }
    })
}

//获取产品筛选列表
export function ProductionType(typeId,subclassId,threeId,page,size) {
    return request({
        url: '/product/product/ProductionType',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: {
            typeId: typeId,
            subclassId: subclassId,
            threeId: threeId,
            page: page,
            size: size
        }
    })
}

//获取产品详情
export function productDetail(id) {
    return request({
        url: '/product/product/productDetail',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
        params: {
            productId: id,
        }
    })
}
