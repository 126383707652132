<template>
    <div>
        <h4>我的订单</h4>
        <el-card class="box-card">
            <el-table :data="orderList" style="width: 100%" height="570" stripe>
                <el-table-column prop="id" label="订单号" width="80" align="center"></el-table-column>
                <el-table-column prop="order_no" label="订单编号" width="200" align="center"></el-table-column>
                <el-table-column prop="addtime" label="下单时间" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.addtime | dateFormat }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="goods_price" label="订单金额" align="center">
                    <template slot-scope="scope">
                        <div>￥{{ scope.row.goods_price }}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="is_send" label="订单状态" align="center">
                    <template slot-scope="scope">
                        <div>{{ scope.row.is_send | orderStatus }}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">修改</el-button>
                        <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>
    </div>
</template>

<script>
import { getOrderPayType } from '../../network/order';
import { userOrderList } from '../../network/user';


export default {
    data() {
        return {
            userData: [],
            orderList: [],
        }
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.storedURL = localStorage.getItem("URL");
        this.queryList()
        this.queryPay()
    },
    onload() {

    },
    methods: {
        // 查询是否支付成功
        queryPay() {
            // 获取地址栏
            const href = window.location.href;
            // 截取地址栏参数
            const orderId = href.split('?')[1].split('&')[1].split('=')[1];
            const data = {
                orderId: orderId,
            };
            getOrderPayType(data).then((res) => {
                if (res.data.code === 1) {
                    this.$message({
                        message: '支付成功',
                        type: 'success'
                    });
                }
            });
        },
        queryList() {
            console.log(1);
            let data = {
                userId: this.userData.id,
            };
            userOrderList(data).then((res) => {
                const results = res.data.data
                this.orderList = results
            });
        },
        handleEdit(index, row) {
            console.log(index, row);
        },
        handleDelete(index, row) {
            console.log(index, row);
        }
    },
    filters: {
        dateFormat: function (val) {// 格式化时间
            const dt = new Date(val * 1000)
            const y = dt.getFullYear()
            const m = dt.getMonth() + 1
            const d = dt.getDate()
            const h = ("0" + dt.getHours()).slice(-2);
            const min = ("0" + dt.getMinutes()).slice(-2);
            const sec = ("0" + dt.getSeconds()).slice(-2);

            return `${y}-${m}-${d} ${h}:${min}:${sec}`;
        },
        orderStatus: function (str) {// 格式化状态
            const status = {
                "10": "订单已确认,等待进行中",
                "9": "订单已取消",
                "8": "已完成",
                "7": "订单已退款",
                "6": "订单待退款",
                "5": "订单已评价",
                "4": "已发货",
                "3": "待收货",
                "2": "已支付,待发货",
                "1": "未支付",
                "0": ""
            }
            return status[str];
        }
    }
}
</script>

<style scoped lang='scss'></style>