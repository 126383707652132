<template>
  <div>
    <Header></Header>

    <div class="carousel">
      <div class="item">
        <div class="carousel-right" v-loading="loading">
          <!-- <el-cascader v-model="value" :options="options" :props="{ expandTrigger: 'hover',checkStrictly: true,value: 'id', label: 'name' }" @change="handleChange">
          </el-cascader> -->
          <div style="text-align: center;" @click="goproduct">所有产品</div>
          <el-cascader-panel :options="options"
            :props="{ expandTrigger: 'hover', checkStrictly: true, value: 'id', label: 'name' }" @change="cascaderPanel">
          </el-cascader-panel>
        </div>
        <div class="carousel-left">
          <el-carousel trigger="click" height="400px">
            <el-carousel-item v-for="(item, index) in imgList" :key="index">
              <img :src="storedURL + item.picurl" class="d-block w-100 h-100" alt="" @click="gotoUrl(item.url)">
            </el-carousel-item>
          </el-carousel>
        </div>
      </div>


    </div>

    <div class="css-card">
      <div class="cards" style="width: 33%;">
        <div class="card-content">
          <div class="card-left">
            <div class="left-title">工具</div>
            <div class="left-content">
              <div class="item">在线转换器</div>
              <div class="item">Scheme-it</div>
              <div class="item">参考设计库</div>
              <div class="item">对照检索</div>
            </div>
          </div>
          <div class="card-right">
            <img src="../../assets/image/card1.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="cards" style="width: 33%;">
        <div class="card-content">
          <div class="card-left">
            <div class="left-title">服务</div>
            <div class="left-content">
              <div class="item">零件追踪</div>
              <div class="item">数字化解决方案</div>
              <div class="item">设计与集成服务</div>
              <div class="item">产品服务</div>
            </div>
          </div>
          <div class="card-right">
            <img src="../../assets/image/card2.jpg" alt="">
          </div>
        </div>
      </div>
      <div class="cards" style="width: 33%;">
        <div class="card-content">
          <div class="card-left">
            <div class="left-title">内容</div>
            <div class="left-content">
              <div class="item">新产品</div>
              <div class="item">技术论坛</div>
              <div class="item">产品培训模块(PTM)</div>
              <div class="item">视频库</div>
            </div>
          </div>
          <div class="card-right">
            <img src="../../assets/image/card3.jpg" alt="">
          </div>
        </div>
      </div>
    </div>

    <div class="swiper">
      <el-carousel indicator-position="outside">
        <el-carousel-item>
          <div class="swiper-content">
            <div class="item">
              <img src="../../assets/image/swiper1.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper2.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper3.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper4.jpg" alt="">
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-content">
            <div class="item">
              <img src="../../assets/image/swiper5.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper6.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper7.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper8.jpg" alt="">
            </div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div class="swiper-content">
            <div class="item">
              <img src="../../assets/image/swiper9.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper10.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper11.jpg" alt="">
            </div>
            <div class="item">
              <img src="../../assets/image/swiper12.jpg" alt="">
            </div>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- <div class="guide">
      <div class="guide-left">
        <div style="font-size: 18px;color: #fff;font-weight: bold;">网上商城操作指南</div>
        <div style="font-size: 16px;color: #fff;">一站式操作指南，助您快速便捷地订购产品和服务解决方案，获得我们全方位的客户支持。</div>
        <div style="color: red;">浏览指南 ></div>
      </div>
      <div class="guide-right">
        <img src="../../assets/image/index1.jpg" alt="">
      </div>
    </div>

    <div class="brank">
      <div class="brank-list">
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
      </div>
      <div class="brank-list" style="margin-top: 10px;">
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
        <div class="list-item">
          <img src="../../assets/image/logo.png" alt="">
        </div>
      </div>
      <div class="brank-text">
        查看所有品牌 >
      </div>
    </div>

    <div class="content">
      <div style="height: 10px;width: 100%;"></div>
      <div class="hot">
        <div class="hot-title">热门品类</div>
        <div class="hot-content">
          <div class="content-row">
            <div class="item">
              <div class="item-name">链接器</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">无源元件</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">半导体</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">电线电缆</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
          </div>
          <div class="content-row" style="margin-top: 10px;">
            <div class="item">
              <div class="item-name">接触器</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">个人防护装备</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">开关</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">熔断器、插座和断路器</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
          </div>
          <div class="content-row" style="margin-top: 10px;">
            <div class="item">
              <div class="item-name">计算机外围设备</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">测试与测量</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">电源和变压器</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
            <div class="item">
              <div class="item-name">手工具</div>
              <div class="item-img"><img src="../../assets/image/gongan.png" alt=""></div>
            </div>
          </div>
        </div>
      </div>
      <div class="news">
        <div class="news-list">
          <div class="list-item" style="background-color: #fff;">
            <div class="item-img"><img src="../../assets/image/index1.jpg" alt=""></div>
            <div class="item-detail">
              <div class="detail-title">新品抢先看</div>
              <div class="detail-text">欢迎访问我们的新品页面，来看看这个月我们又增加了哪些优质产品吧。</div>
            </div>
            <div class="item-but">
              即刻探索 >
            </div>
          </div>
          <div class="list-item" style="background-color: #fff;">
            <div class="item-img"><img src="../../assets/image/index2.jpg" alt=""></div>
            <div class="item-detail">
              <div class="detail-title">本地库存产品</div>
              <div class="detail-text">25,000+ 产品选择，当天发货，企业客户免运费!</div>
            </div>
            <div class="item-but">
              查看产品 >
            </div>
          </div>
          <div class="list-item" style="background-color: #fff;">
            <div class="item-img"><img src="../../assets/image/index1.jpg" alt=""></div>
            <div class="item-detail">
              <div class="detail-title">电子元器件解决方案</div>
              <div class="detail-text">我们库存有大量的连接器、无源元件、半导体、开关、电源和其他电子元器件产品，涵盖被动元件、主动元件、板级元件、机电产品等。</div>
            </div>
            <div class="item-but">
              开始选购 >
            </div>
          </div>
          <div class="list-item" style="background-color: #fff;">
            <div class="item-img"><img src="../../assets/image/index2.jpg" alt=""></div>
            <div class="item-detail">
              <div class="detail-title">地铁运营解决方案</div>
              <div class="detail-text">我们的端到端产品和服务解决方案为您提供产品全生命周期的支持。</div>
            </div>
            <div class="item-but">
              了解详情 >
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <Footer3 />
  </div>
</template>

<script>
import Footer3 from '@/components/Footer3.vue';
import Header from '@/components/Header.vue';
import { homebanner, Type3 } from '../../network/home'

export default {
  components: {
    Footer3,
    Header
  },
  data() {
    return {
      storedURL: '',
      imgList: [],
      value: [],
      options: [],
      typeid: 0,
      subclassid: 0,
      threeid: 0,
      loading: false,
    }
  },
  created() {
    this.storedURL = localStorage.getItem("URL");
    this.loading = true
    homebanner().then(res => {
      const results = res.data.data;
      this.imgList = results
    })
    Type3().then(res => {
      const results = res.data.data
      this.options = results
      this.loading = false
    })
  },
  onload() {

  },
  methods: {
    gotoUrl(url) {
      window.open(url)
    },
    goproduct() {
      this.$router.push('/product')
    },
    cascaderPanel(e) {
      this.typeid = e[0]
      this.subclassid = e[1]
      this.threeid = e[2]
      this.$router.push({
        name: 'product',
        params: {
          typeid: this.typeid,
          subclassid: this.subclassid,
          threeid: this.threeid,
        }
      })
    }
  },
}
</script>

<style lang='scss'>
.carousel {
  width: 100%;
  height: 400px;

  .item {
    width: 85%;
    height: 400px;
    margin: 0 auto;
    display: flex;

    .carousel-left {
      width: 85%;
      height: 400px;
    }

    .carousel-right {
      max-width: 45%;
      background-color: #fff;
      height: 400px;
    }
  }

}

.carousel-item {
  width: 100%;
  height: 450px;

  img {
    width: 100%;
    height: 100%;
  }
}

.css-card {
  width: 85%;
  margin: 10px auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .cards {
    .card-content {
      height: 220px;
      display: flex;
      justify-content: space-between;
      background-color: #e6e6e6;

      .card-left {
        width: 50%;
        font-weight: bold;

        .left-title {
          font-size: 18px;
          margin-top: 20px;
          padding-left: 20px;
        }

        .left-content {
          padding-left: 10px;
          font-size: 14px;

          .item {
            margin-top: 10px;
            height: 30px;
            line-height: 30px;
            padding-left: 10px;
          }

          .item:hover {
            border-left: 3px solid red;
          }
        }
      }

      .card-right {
        width: 50%;
        height: 220px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .cards:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  }
}


.swiper {
  width: 85%;
  margin: 20px auto;
}

.swiper-content {
  display: flex;
  justify-content: space-between;

  .item {
    width: 24%;
    height: 310px;

  }

  .item:hover {
    box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
  }
}

.guide {
  width: 85%;
  height: 140px;
  background-color: #393939;
  margin: 30px auto;
  display: flex;

  .guide-left {
    text-align: left;
    width: 55%;
    height: 140px;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .guide-right {
    width: 45%;
    height: 140px;

    img {
      width: 100%;
      height: 100%;

    }
  }
}

.brank {
  width: 85%;
  height: 250px;
  margin: 30px auto;

  .brank-list {
    width: 100%;
    height: 30%;
    display: flex;
    justify-content: space-between;

    .list-item {
      width: 16%;
      height: 100%;

      img {
        width: 100%;
        height: 100%;
      }
    }
  }

  .brank-text {
    height: 40%;
    width: 100%;
    text-align: right;
    margin-top: 50px;
    color: blueviolet;
  }
}

.content {
  width: 100%;
  height: 800px;
  background-color: #f5f5f5;

  .hot {
    width: 85%;
    height: 380px;
    margin: 20px auto;

    .hot-title {
      height: 30px;
      width: 100%;
      text-align: left;
      font-size: 20px;
      font-weight: bold;
      color: black;
    }

    .hot-content {
      width: 100%;
      height: 350px;
      margin-top: 10px;

      .content-row {
        width: 100%;
        height: 25%;
        display: flex;
        justify-content: space-between;

        .item {
          width: 23%;
          height: 100%;
          display: flex;
          background: #fff;

          .item-name {
            width: 55%;
            text-align: left;
            margin-top: 10px;
            margin-left: 10px;
          }

          .item-img {
            width: 45%;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }

        .item:hover {
          box-shadow: 0 2px 4px rgba(0, 0, 0, .2);
        }
      }
    }
  }

  .news {
    width: 85%;
    height: 360px;
    margin: 20px auto;

    .news-list {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;

      .list-item {
        width: 23%;
        height: 100%;

        .item-img {
          height: 45%;
          width: 100%;

          img {
            width: 100%;
            height: 100%;
          }
        }

        .item-detail {
          height: 45%;
          width: 100%;

          .detail-title {
            height: 30%;
            line-height: 60px;
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            margin-left: 15px;
          }

          .detail-text {
            text-align: left;
            margin-left: 15px;
            font-size: 16px;
          }
        }

        .item-but {
          height: 10%;
          text-align: left;
          margin-left: 15px;
          color: blueviolet;
        }
      }
    }
  }
}

@media screen and (max-width: 400px) {
  .carousel {
    width: 100%;
    height: 250px;
    display: flex;

    .carousel-left {
      width: 100%;
      height: 250px;
      margin: 0 auto
    }
  }

  .guide {
    width: 100%;

    .guide-left {
      font-size: 14px;
    }
  }

  .brank {
    width: 100%;
  }

  .hot {
    width: 100%;
  }

  .news {
    width: 100%;

    .news-list {
      width: 100%;
      height: 100%;
    }
  }
}</style>