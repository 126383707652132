<template>
  <div>
    <Header />
    
    <div class="mbx">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item"><router-link to="/">首页</router-link></li>
          <li class="breadcrumb-item active" aria-current="page">关于我们</li>
        </ol>
      </nav>
    </div>

    <div class="content">
      关于我们
    </div>

    <Footer />
  </div>
</template>

<script>
import Footer from '@/components/Footer.vue';
import Header from '@/components/Header.vue';
export default {
  components: {
    Footer,
    Header
  },
  data() {
    return {

    }
  },
  created() {

  },
  onload() {

  },
  methods: {

  },
}
</script>

<style scoped lang='scss'>
a{
  text-decoration: none;
  color: black;
}
.router-link-active {
  text-decoration: none;
}
.mbx {
  width: 100%;
  height: 60px;
  margin-left: 60px;
}
.content {
  width: 90%;
  margin: 30px auto;
}
</style>