<template>
    <div>
        <el-container class="layout-container">
            <el-aside class="aside" width="200px">
                <el-menu :default-active="$route.path" background-color="#002033" text-color="#fff"
                    active-text-color="#ffd04b" router>
                    <el-menu-item index="/">
                        <i class="el-icon-s-home"></i>
                        <span slot="title">首页</span>
                    </el-menu-item>
                    <el-menu-item index="/userHome/userOrder">
                        <i class="el-icon-s-order"></i>
                        <span slot="title">我的订单</span>
                    </el-menu-item>
                    <el-menu-item index="/userHome/userCart">
                        <i class="el-icon-s-goods"></i>
                        <span slot="title">我的购物车</span>
                    </el-menu-item>
                    <el-menu-item index="/userHome/userCoupon">
                        <i class="el-icon-s-ticket"></i>
                        <span slot="title">我的优惠卷</span>
                    </el-menu-item>
                    <el-menu-item index="/userHome/userAddress">
                        <i class="el-icon-location-information"></i>
                        <span slot="title">地址管理</span>
                    </el-menu-item>
                    <el-menu-item index="/userHome/userSetting">
                        <i class="el-icon-s-custom"></i>
                        <span slot="title">个人资料</span>
                    </el-menu-item>
                    <el-menu-item index="/userHome">
                        <i class="el-icon-setting"></i>
                        <span slot="title">账号安全</span>
                    </el-menu-item>
                </el-menu>
            </el-aside>
            <el-container>
                <el-header class="header">
                    <div class="header-user">
                        <el-col>
                            <el-dropdown @command="handleCommand">
                                <span class="flex">
                                    <!-- 用户: {{ userData.username }} -->
                                    用户：<el-avatar size="medium" :src="userData.avatar"></el-avatar>
                                </span>
                                <el-dropdown-menu slot="dropdown">
                                    <el-dropdown-item command="exit">退出登录</el-dropdown-item>
                                </el-dropdown-menu>
                            </el-dropdown>
                        </el-col>
                    </div>
                </el-header>
                <el-main class="main">
                    <router-view />
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>

<script>
import ProductHeader from '@/components/product/ProductHeader.vue';

export default {
    components: {
        ProductHeader,
    },
    data() {
        return {
            userData: [],
        }
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
    },
    onload() {

    },
    methods: {
        handleCommand(command) {
            if (command == 'exit') {
                localStorage.removeItem("userId");
                localStorage.removeItem("userData");
                this.$message('退出成功');
                this.$router.push('/login');
            }
        }
    },
}
</script>

<style scoped lang='scss'>
.layout-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.aside {
    background-color: rgb(0, 32, 51);

    .aside-menu {
        height: 100%;
    }
}

.header {
    background: #b3c0d1;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: right;
    align-items: center;
}

.main {
    background-color: #fff;
}

.flex {
    display: flex;
    color: white;
    font-size: 16px;
    align-items: center;
}
</style>