<template>
  <div>
    <div class="subscribe">
      <div class="subscribe-text">保持联系 !</div>
      <div class="subscribe-input">
        <el-input placeholder="请输入您的电子邮件" v-model="input" class="input-with-select">
          <el-button style="background-color: red;color: #fff;" slot="append">订阅</el-button>
        </el-input>
      </div>
    </div>
    <div class="wrapper">
      <div class="wrapper-content">
        <div class="content-item">
          <div class="item-title">信息</div>
          <div class="item-list">
            <div class="a"><a href="">关于祖荣</a></div>
            <div class="a"><a href="">市场</a></div>
            <div class="a"><a href="">在 zr721.com 上售卖</a></div>
            <div class="a"><a href="">职业</a></div>
            <div class="a"><a href="">站点地图</a></div>
            <div class="a"><a href="">新闻编辑室</a></div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-title">帮助</div>
          <div class="item-list">
            <div class="a"><a href="">帮助和支持</a></div>
            <div class="a"><a href="">订单状态</a></div>
            <div class="a"><a href="">运费</a></div>
            <div class="a"><a href="">退货和订单问题</a></div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-title">联系我们</div>
          <div class="item-list">
            <div class="a">
              <i class="el-icon-phone-outline"></i> 123456788910
            </div>
            <div class="a">
              <i class="el-icon-message"></i> 123456788910@qq.com
            </div>
             
            <div class="a"><a href="https://beian.miit.gov.cn/">粤ICP备 2022003163号</a></div>
            <div class="a"><a href=""> 珠海工商</a></div>
            <div class="a">
              <img src="../assets/image/logo1.png" alt="">
            </div>
          </div>
        </div>
        <div class="content-item">
          <div class="item-title">我们接受</div>
          <div class="item-list">
            <div class="imglist">
              <img src="../assets/image/zfbzflogo.png" alt="">
              <img style="margin-left: 10px;" src="../assets/image/wxzflogo.png" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper-footer">
        <div>© ZR Components Ltd 珠海祖荣科技有限公司 中国广东省珠海市吉大园林路1号47栋1104房-1; 邮编: 519000</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      input: '',
    }
  },
  created() {

  },
  onload() {

  },
  methods: {

  },
}
</script>

<style scoped lang='scss'>
@media screen and (max-width: 400px) {
  .subscribe {
    height: 100px;
    width: 100%;
    background-color: #444444;
    border-top: 2px solid #000000;

    .subscribe-text {
      color: white;
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }

    .subscribe-input {
      width: 70%;
      margin: 10px auto;
    }
  }
  .wrapper {
    width: 100%;
    background-color: #111111;
    .wrapper-content {
      width: 85%;
      margin: 0 auto;
      .content-item {
        width: 100%;
        height: auto;
        .item-title {
          color: #fff;
          font-weight: bold;
          font-size: 14px;
          text-align: center;
        }
        .item-list {
          color: #eee;
          font-size: 12px;
          margin-top: 10px;
          text-align: center;
          .a {
            margin-top: 5px;
          }
          .imglist {
            display: flex;
            justify-content: center;
            img {
              width: 30px;
              height: 30px;
            }
          }
          img {
            width: 130px;
            height: 50px;
          }
          a {
            text-decoration: none;
            color: #a59e9e;
          }
        }
      }
    }
    .wrapper-footer {
      width: 100%;
      text-align: center;
      color: #a59e9e;
      margin-top: 15px;
    }
  }
}

@media screen and (min-width: 400px) {
  .subscribe {
    height: 100px;
    width: 100%;
    background-color: #444444;
    border-top: 2px solid #000000;
    padding: 10px;

    .subscribe-text {
      color: white;
      width: 100%;
      text-align: center;
    }

    .subscribe-input {
      width: 30%;
      margin: 10px auto;
    }
  }

  .wrapper {
    width: 100%;
    height: 300px;
    background-color: #111111;

    .wrapper-content {
      width: 85%;
      height: 250px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .content-item {
        width: 24%;
        height: 100%;
        padding-top: 30px;
        .item-title {
          color: #fff;
          font-weight: bold;
          font-size: 14px;
        }

        .item-list {
          color: #eee;
          font-size: 12px;
          margin-top: 20px;
          .a {
            margin-top: 5px;
          }
          .imglist {
            display: flex;
            img {
              width: 30px;
              height: 30px;
            }
          }
          img {
            width: 130px;
            height: 50px;
          }
          a {
            text-decoration: none;
            color: #a59e9e;
          }
        }
      }
    }
    .wrapper-footer {
      width: 100%;
      text-align: center;
      color: #a59e9e;
      margin-top: 15px;
    }
  }
}</style>