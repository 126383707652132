//引入封装的公共的请求插件
import request from '../network/request'

//获取类别列表
export function Type3() {
    return request({
        url: '/product/product/type3',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
    })
}

//获取轮播图
export function homebanner() {
    return request({
        url: '/homebanner/homebanner/list',
        method: 'POST',
        headers: 'X-Requested-With: XMLHttpRequest',
    })
}