<template>
    <div>
        <h4>个人设置</h4>
        <el-card class="box-card" v-loading="loading">
            <el-form ref="form" label-width="80px">
                <el-form-item label="用户头像">
                    <div style="display: flex;justify-content: space-between;">
                        <el-upload class="avatar-uploader" action="https://zrw.sdsj88.cn/home/user/user/uploadImage"
                            :show-file-list="false" :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload"
                            :limit="1">
                            <img v-if="userInfo.avatar" :src="userInfo.avatar" class="avatar">
                            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                        </el-upload>
                    </div>

                </el-form-item>
                <el-form-item label="用户名称">
                    <el-input v-model="userInfo.name"></el-input>
                </el-form-item>
                <el-form-item label="联系电话">
                    <el-input v-model="userInfo.username" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item prop="email" label="邮箱">
                    <el-input v-model="userInfo.email"></el-input>
                </el-form-item>
                <el-form-item label="性别">
                    <el-select v-model="userInfo.gender" placeholder="请选择">
                        <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div class="middle">
                <el-button type="primary" @click="onSubmit">提交修改</el-button>
            </div>
        </el-card>
    </div>
</template>

<script>
import { getUserInfo, editUserInfo } from '../../network/user';

export default {
    data() {
        return {
            userData: [],
            userInfo: [],
            storedURL: '',
            avatar: '',
            options: [
                {
                    value: '1',
                    label: '男'
                }, {
                    value: '2',
                    label: '女'
                }
            ],
            loading: false,
        }
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        this.storedURL = localStorage.getItem("URL");
        this.getinfo()
    },
    onload() {

    },
    methods: {
        onSubmit() {
            this.loading = true
            let data = {
                userId: this.userData.id,
                avatar: this.userInfo.avatar,
                name: this.userInfo.name,
                email: this.userInfo.email,
                gender: this.userInfo.gender,
            }
            editUserInfo(data).then(res => {
                const results = res.data.data
                this.userInfo = results
                localStorage.setItem("userData", JSON.stringify(this.userInfo));
                this.loading = false
            })
        },
        getinfo() {
            this.loading = true
            let data = {
                userId: this.userData.id
            }
            getUserInfo(data).then(res => {
                const results = res.data.data
                this.userInfo = results
                this.loading = false
            })
        },
        handleAvatarSuccess(res, file) {
            this.userInfo.avatar = this.storedURL + res.data;
        },
        beforeAvatarUpload(file) {
            console.log(file);
            const isJPG = file.type === 'image/jpeg';
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error('上传头像图片只能是 JPG 格式!');
            }
            if (!isLt2M) {
                this.$message.error('上传头像图片大小不能超过 2MB!');
            }
            return isJPG && isLt2M;
        }
    },
}
</script>

<style scoped lang='scss'>
.middle {
    display: flex;
    justify-content: center;
}

.avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
}

.avatar-uploader .el-upload:hover {
    border-color: #409EFF;
}

.avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 100px;
    height: 100px;
    line-height: 100px;
    text-align: center;
}

.avatar {
    width: 100px;
    height: 100px;
    display: block;
}</style>