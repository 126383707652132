<template>
  <div>
    <h4>地址管理</h4>
    <el-card class="box-card">
      
    </el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      email: '',
    }
  },
  created() {

  },
  onload() {

  },
  methods: {

  },
}
</script>

<style scoped lang='scss'></style>