<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created() {
    // localStorage.setItem("URL", "https://zrw.sdsj88.cn");//保存数据
    localStorage.setItem("URL", "https://zrwadmin.cjpco.cn");//保存数据
  }
}      
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  background-color: #f5f5f5;

  a {
    text-decoration: none;
  }

  .router-link-active {
    text-decoration: none;
  }

  .el-cascader-panel {
    height: 375px;
    max-height: 375px;
    border-radius: 4px;
  }

  // .el-cascader-node {

  //   width: 250px;

  //   /* width: 200px; */

  // }


  .el-scrollbar__wrap {
    overflow: initial !important
  }

  .el-cascader-panel .el-radio {
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .el-cascader-panel .el-radio__input {
    visibility: hidden;
  }

  .el-cascader-panel .el-cascader-node__postfix {
    top: 10px;
  }

}
</style>
