//引入axios异步请求插件
import axios from 'axios'

// 创建一个axios实例
const request = axios.create({
  // baseURL: 'https://zrw.sdsj88.cn/home/',
  baseURL: 'https://zrwadmin.cjpco.cn/home/',
  timeout: 10000,
  transformResponse: [function (data) { // data数据为原始数据 未经处理的json格式
    try {
      return JSON.parse(data)
    } catch (error) {
      return data
    }
  }]
})

// 导出实例
export default request