<template>
  <div>
    <el-divider></el-divider>
    <div class="toplist">
        <div class="topitem">
            <div><img src="../assets/image/bag.png" alt=""></div>
            <div class="item-text">原厂授权 正品保障</div>
        </div>
        <div class="topitem">
            <div><img src="../assets/image/bag.png" alt=""></div>
            <div class="item-text">自营现货 极速发货</div>
        </div>
        <div class="topitem">
            <div><img src="../assets/image/bag.png" alt=""></div>
            <div class="item-text">品类齐全 一站采购</div>
        </div>
        <div class="topitem">
            <div><img src="../assets/image/bag.png" alt=""></div>
            <div class="item-text">满99包邮 省心省事</div>
        </div>
    </div>
    
    <div style="width: 90%;margin: 0 auto;"><el-divider></el-divider></div>
    
  
    <div class="middle">
        <div class="middle-left">
            <div class="left-item">
                <div class="item-title">关于祖荣</div>
                <div class="item-content">
                    <div class="content-item">关于我们</div>
                    <div class="content-item">荣誉风采</div>
                    <div class="content-item">授权代理</div>
                    <div class="content-item">联系我们</div>
                </div>
            </div>
            <div class="left-item">
                <div class="item-title">新手入门</div>
                <div class="item-content">
                    <div class="content-item">注册登录</div>
                    <div class="content-item">找回密码</div>
                    <div class="content-item">收藏功能</div>
                    <div class="content-item">BOM配单</div>
                </div>
            </div>
            <div class="left-item">
                <div class="item-title">购物指南</div>
                <div class="item-content">
                    <div class="content-item">购物流程</div>
                    <div class="content-item">搜索商品</div>
                    <div class="content-item">订单管理</div>
                    <div class="content-item">合同下载</div>
                </div>
            </div>
            <div class="left-item">
                <div class="item-title">支付&配送</div>
                <div class="item-content">
                    <div class="content-item">支付方式</div>
                    <div class="content-item">账户信息</div>
                    <div class="content-item">支付安全</div>
                    <div class="content-item">运费说明</div>
                </div>
            </div>
            <div class="left-item">
                <div class="item-title">售后服务</div>
                <div class="item-content">
                    <div class="content-item">售后服务</div>
                    <div class="content-item">发票须知</div>
                    <div class="content-item">销售条款</div>
                    <div class="content-item">注册协议</div>
                </div>
            </div>
        </div>
        <div class="middle-right">
            <div class="right-left">
                <div class="left-img">
                    <img src="../assets/image/bag.png" alt="">
                </div>
                <div class="left-phone">400-870-9968</div>
                <div class="left-time">服务时间：8:30-17:30</div>
            </div>
            <div class="right-right">
                <div class="right-img">
                    <img src="../assets/image/gzhewm.png" alt="">
                </div>
                <div class="right-text">关注祖荣公众号</div>
            </div>
        </div>
    </div>
  
    <div class="bottom">
        
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },
  created() {

  },
  onload() {

  },
  methods: {

  },
}
</script>

<style scoped lang='scss'>
.toplist {
    width: 90%;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;
    .topitem {
        width: 20%;
        display: flex;
        img{
            width: 50px;
            height: 50px;
        }
        .item-text {
            height: 50px;
            line-height: 50px;
        }
    }
}
.middle {
    width: 90%;
    margin: 10px auto;
    display: flex;
    .middle-left {
        width: 70%;
        height: 200px;
        display: flex;
        justify-content: space-around;
        .left-item {
            width: 15%;
            .item-title {
                width: 100%;
                height: 30px;
                font-size: 14px;
                font-weight: bold;
                text-align: center;
                border-bottom: 1px solid #eee;
            }
            .item-content {
                text-align: center;
                font-size: 13px;
                .content-item {
                    margin-top: 10px;
                }
            }
        }
    }
    .middle-right {
        width: 30%;
        height: 200px;
        display: flex;
        justify-content: space-around;
        .right-left {
            width: 50%;
            text-align: center;
            margin-top: 30px;
            .left-img {
                img{
                    width: 50px;
                    height: 50px;
                }
            }
            .left-phone{
                font-weight: bold;
                font-size: 16px;
            }
            .left-time {
                font-size: 13px;
            }
        }
        .right-right{
            margin-top: 30px;
            .right-img {
                img{
                    width: 100px;
                    height: 100px;
                }
            }
            .right-text {
                text-align: center;
                font-size: 13px;
            }
        }
    }
}
.bottom {
    width: 100%;
    height: 100px;
    background-color: #32000f;
}
</style>