<template>
    <div>
        <div class="top">
            <div class="top-left" v-if="userData === '' || userData === null">
                <div><router-link to="/login" style="color: white;">登录</router-link></div>
                <div><router-link to="/register" style="color: #ff8000;">注册</router-link></div>
            </div>
            <div class="top-left" v-if="userData !== '' && userData !== null">
                <div style="color: #fff;">用户：{{ userPhone }}</div>
            </div>
            <div class="top-right">
                <div><router-link to="/userOrder" style="color: white;">我的订单</router-link></div>
                <div>会员中心</div>
                <div>联系客服</div>
            </div>
        </div>
        <div class="middle">
            <div class="middle-left">
                <img src="../../assets/image/logo.png" alt="">
            </div>
            <div class="middle-right">
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="请输入产品编号或关键字" aria-label="Recipient's username"
                        aria-describedby="basic-addon2">
                    <span class="input-group-text" id="basic-addon2">搜索</span>
                </div>
            </div>
            <div class="middle-but">
                <button type="button" class="btn btn-outline-info" @click="goCart()">我的购物车</button>
            </div>
        </div>
        <div class="nav">
            <div class="nav-item"><router-link to="/" style="color: black;">首页</router-link></div>
            <div class="nav-item">商品目录</div>
            <div class="nav-item">品牌专区</div>
            <div class="nav-item">BOM配单</div>
            <div class="nav-item">授权代理</div>
            <div class="nav-item">供应商合作</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ProductHeader',
    data() {
        return {
            userData: [],
            userPhone: '',
        }
    },
    created() {
        this.userData = JSON.parse(localStorage.getItem("userData"));
        console.log(this.userData);
        if (this.userData != null) {
            this.userPhone = this.userData.username
        }
    },
    onload() {

    },
    methods: {
        goCart() {
            if(this.userData == '' || this.userData == null) {
                alert('请先登录')
                this.$router.push('/login')
            }else {
                const id = this.userData.id
                this.$router.push({ path: '/userHome/userCart', query: { id: id } })
            }
        }
    },
}
</script>

<style scoped lang='scss'>
.top {
    background-color: #353535;
    width: 100%;
    height: 37px;
    line-height: 37px;
    font-size: 13px;
    display: flex;
    justify-content: space-between;

    .top-left {
        width: 15%;
        display: flex;
        justify-content: space-around;
        margin-left: 100px;

    }

    .top-right {
        width: 20%;
        display: flex;
        color: white;
        justify-content: space-around;
        margin-right: 100px;
    }
}
.middle {
    width: 100%;
    height: 120px;
    display: flex;
    .middle-left {
        width: 20%;
        height: 120px;
        margin-left: 100px;
        img {
            width: 100%;
            height: 100%;
        }
    }
    .middle-right {
        width: 40%;
        height: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5%;
    }
    .middle-but {
        width: 10%;
        margin-left: 10%;
        display: flex;
        align-items: center;
        .but {
            height: 40px;
            line-height: 40px;
            background-color: #f6f8fc;
            display: flex;
            padding: 5px;
            .but-img {
                height: 100%;
                img {
                    width: 20px;
                    height: 20px;
                }
            }
            .but-text {
                 margin-left: 5px;
            }
        }
    }
}
.nav {
    width: 100%;
    height: 30px;
    line-height: 30px;
    color: black;
    font-weight: bold;
    display: flex;
    justify-content: center;
    .nav-item {
        margin-left: 40px;
    }
}
</style>